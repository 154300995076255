import React, {useEffect, useRef, useState} from "react";
import "./CustomSelectBox.css"
export default function CustomSelectBox({options=[], customOnChange, fieldTitle, titleInfo, type = "text", redirectEdit, placeholder, onChange, hasIcon, iconAlt, iconOnClick, defaultValue, disabled = false}) {
    const [currentSelectedValue, setCurrentSelectedValue] = useState()

    const [isOpen, setIsOpen] = useState(false)
    const [activeElement, setActiveElement] = useState(false);

    const trigger = useRef(null)
    const menu = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!menu?.current?.contains(event.target) && !trigger?.current?.contains(event.target)) {
                setIsOpen(false);
                setActiveElement(false);
            }else{
                setActiveElement(true);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [trigger, menu])

    useEffect(()=>{
        setCurrentSelectedValue(defaultValue)
    }, [defaultValue])

    return (
        <div className={"textbox-wrapper selectbox"}>
            <div className={"textbox-top"}>
                <div className={"textbox-title-wrapper"}>
                    <span className={"textbox-title"}>{fieldTitle}</span>
                    {!!titleInfo &&
                        <img src={"images/account/fieldTitleInfo.svg"} alt={"info"}/>
                    }
                </div>
                {!!redirectEdit &&
                    <span className={"textbox-redirectEdit"} onClick={()=>{redirectEdit()}}>Επεξεργασία</span>
                }
            </div>
            <div className={"selectbox"}>
                <div className={`textbox-bot selectbox ${(disabled || !!redirectEdit) ? "disabled" : ""}`} tabIndex={0}
                     onFocus={()=>{if(!disabled)setIsOpen(prev => !prev)}}
                     onBlur={()=>{ if(!disabled)if(!activeElement)setIsOpen(false)}}
                     onMouseDown={()=>{
                         if(isOpen) {
                             setIsOpen(false)
                         }else if(document.activeElement === trigger.current){
                             if(!disabled)
                             setIsOpen(prev => !prev)
                         }
                     }}

                     ref={trigger}>
                    <span className={`textbox-input ${currentSelectedValue ?"":"selectbox-placeholder"}`}>{currentSelectedValue ? currentSelectedValue : placeholder}</span>
                    {isOpen && <img src={`images/account/dropDownIconUp.svg`}
                                    alt={'arrow drop up'} width={24} height={24}/>}
                    {!isOpen && <img src={`images/account/dropDownIconDown.svg`}
                                     alt={'arrow drop down'} width={24} height={24}/>}
                </div>
                {isOpen &&
                    <ul className={"selectbox-dropdownMenu"}
                        ref={menu}>
                        {options.map((item, index) =>
                            <li key={index}
                                className={"selectbox-dropdownMenu-item"}
                                onClick={() => {
                                    if(!!customOnChange) customOnChange(item)
                                    setCurrentSelectedValue(item)
                                    setIsOpen(false);
                                }}>
                                {item}
                            </li>
                        )}
                    </ul>
                }
            </div>
        </div>
    );
}