import React, {useState} from "react";
import "./ProductContainer.css";
import CmsHelper, {PickProgramPageHelper} from "../../../Utilities/CmsHelpers";
import {Button} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ProductPriceInfo from "../../ProductPriceInfo";
import TextPreProcessor from "../../../Utilities/TextPreProcessor";
import {useLocation, useNavigate} from "react-router-dom";
import {useCms} from "../../../../context/CmsContext";
import {useAuth} from "../../../../context/AuthContext";
import {useAccount} from "../../../../context/AccountContext";

function ProductContainer({product, index, setChosenProgram, packagesRef, chosenProgram, onNext, update, couponExists, packagesLength}){
    let navigate = useNavigate();
    let location = useLocation();
    const unsubscribeFlow = location.state ?? {};

    const {pickProgramData} = useCms();
    const defaultPrograms = PickProgramPageHelper.getPrograms(pickProgramData, CmsHelper.DEFAULT);
    const programInfo = PickProgramPageHelper.getProgramInfo(defaultPrograms);
    const cmsProgramData = PickProgramPageHelper.getProgram(programInfo, product.displayName);
    const cmsProgramDetails = PickProgramPageHelper.getProgramDetails(programInfo, product.displayName);
    const cmsProgramDisclaimers = PickProgramPageHelper.getProgramDisclaimers(programInfo, product.displayName);

    let account = useAccount();

    const {setCheckLoggedIn } = useAuth();


    const [expandListId, setExpandListId] = useState(null);
    const onCancelSubscription = () => {
        setCheckLoggedIn(false);
        navigate("/Unsubscribe", {state: unsubscribeFlow});
    };

    const onExpandUSPList = (productId) => {
        let value = expandListId;

        if (value === productId) {
            setExpandListId(null);
        } else {
            setExpandListId(productId);
        }
    };



    const onProgramChange = (program) => {
        setChosenProgram(program);
        account.setChosenProgramName(program.name);
    };

    return (
        <div
            className="product-container"
            id={`${product.displayName}`}
            key={index}
            ref={(el) => (packagesRef.current[index] = el)}
        >
            <div
                className={`single-product ${chosenProgram.productId === product.productId &&
                chosenProgram.pricePoint === product.pricePoint
                    ? "active"
                    : ""
                }`}
                key={index}
                onClick={() => onProgramChange(product)}
            >
                {cmsProgramData.duration && (
                    <div className="message-box-container start">
                        <div className={"message-box msg-desktop ".concat(PickProgramPageHelper.getColorClass(cmsProgramData.duration))}>
                            {cmsProgramData.duration}
                        </div>
                    </div>
                )}

                {cmsProgramData.borderMsg && (<div className="message-box-container centered">
                    <div className="message-box black">{cmsProgramData.borderMsg}</div>
                </div>)}
                <div className="single-product-wrapper">
                    <div className="single-product-content">
                        <div className="basic-info">
                            <div className="title-wrapper">
                                <div className="title">
                                    <div className="main">
                                        {cmsProgramData.title}
                                    </div>
                                    <div>{cmsProgramData.secondaryTitle}</div>
                                </div>

                                {cmsProgramData.duration && (
                                    <div className={"message-box msg-mobile ".concat(PickProgramPageHelper.getColorClass(cmsProgramData.duration))}>
                                        <span className="duration-break">{cmsProgramData.duration}</span>
                                    </div>
                                )}

                            </div>

                            <div className="price-action-desktop">
                                <ProductPriceAndAction
                                    product={product}
                                    chosenProgram={chosenProgram}
                                    update={update}
                                    coupon={couponExists}
                                    onNext={onNext}
                                    expandListId={expandListId}
                                    onExpandUSPList={onExpandUSPList}
                                    migrateFromCancellation={
                                        account.migrateFromCancellation
                                    }
                                    cmsInfo={cmsProgramData}
                                />
                            </div>
                        </div>

                        <div
                            className={`product-usps ${expandListId === product.productId ? "expand" : ""
                            }`}
                        >

                            <USPs
                                programDetails={cmsProgramDetails}
                                mainDetails={cmsProgramData.detailsMain} />
                        </div>

                        <div className="price-action-mobile">
                            <ProductPriceAndAction
                                product={product}
                                chosenProgram={chosenProgram}
                                update={update}
                                coupon={couponExists}
                                onNext={onNext}
                                expandListId={expandListId}
                                onExpandUSPList={onExpandUSPList}
                                cmsInfo={cmsProgramData}
                                migrateFromCancellation={
                                    account.migrateFromCancellation
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>

            {account.migrateFromCancellation && packagesLength > 0 && (
                <MigrateActions
                    product={product}
                    onNext={onNext}
                    onCancelSubscription={onCancelSubscription}
                />
            )}
            <Disclaimer disclaimers={cmsProgramDisclaimers} />
        </div>
    )
}

function ProductPriceAndAction({
                                   product,
                                   chosenProgram,
                                   update,
                                   coupon,
                                   onNext,
                                   expandListId,
                                   onExpandUSPList,
                                   migrateFromCancellation,
                                   cmsInfo,
                               }) {
    return (
        <>
            <Button
                variant="text"
                color="primary"
                className={`more-button-mobile ${expandListId === product.productId ? "active" : ""
                }`}
                endIcon={<ArrowDownwardIcon id="arrow-icon" />}
                onClick={() => onExpandUSPList(product.productId)}
            >
                {expandListId === product.productId ? "λιγότερα" : "περισσότερα"}
            </Button>
            <div className="plus-item">
                {cmsInfo && cmsInfo.message && (
                    <>
                        <img src="images/plus-sign.png" alt="ant1 originals" />
                        <span>
              {cmsInfo.message}
            </span>
                    </>
                )}

            </div>
            <div className="price">
                <ProductPriceInfo displayName={product.displayName} coupon={coupon} cmsInfo={cmsInfo} />
            </div>

            {!migrateFromCancellation && (<Button
                    variant={
                        product.productId === chosenProgram.productId &&
                        product.pricePoint === chosenProgram.pricePoint
                            ? "contained"
                            : "outlined"
                    }
                    color="primary"
                    onClick={() => onNext(product)}
                    className="action-button"
                >
                    {coupon
                        ? "Συνέχεια"
                        : update
                            ? "Αναβάθμιση"
                            : product.displayName === "Lite"
                                ? "Δωρεάν εγγραφή"
                                : "Eπιλογή"}
                </Button>
            )}

        </>
    );
}

function USPs({ programDetails, mainDetails }) {
    let columns = [];
    programDetails.info.forEach(i => {
        if (!columns.includes(i.displayColumn))
            columns.push(i.displayColumn);
    })

    return (programDetails &&
        <>
            <div className={"uspsTablet"}>
                <ul className={"item"}>
                    {programDetails.info
                        .filter((item, index) => item.type === "All" && index < Math.ceil(programDetails.info.filter(item => item.type === "All").length / 2))
                        .map((item, index) =>
                            <div key={index}
                                 className={`${item.noMargin ? "no-margin" : ""}`}>
                                <TextPreProcessor text={item.value}/><ImageContent images={item.images}
                                                                                   height={item.imagesHeight}/>
                            </div>)}
                </ul>
                <ul className={"item"}>
                    {programDetails.info
                        .filter((item, index) => item.type === "All" && index >= Math.ceil(programDetails.info.filter(item => item.type === "All").length / 2))
                        .map((item, index) =>
                            <div key={index}
                                 className={`${item.noMargin ? "no-margin" : ""}`}>
                                <TextPreProcessor text={item.value}/><ImageContent images={item.images}
                                                                                   height={item.imagesHeight}/>
                            </div>)}
                </ul>
            </div>
            <div className={"uspsOther"}>
            {columns.sort().map((column, columnIdx) =>
                (<div className="item" key={columnIdx}>
                        {programDetails.info
                            .filter(item => item.displayColumn === column && item.type === "All")
                            .map((item, index) =>
                                <div key={index}
                                     className={`${item.showOnExpand ? "show-on-expand" : ""} ${item.noMargin ? "no-margin" : ""}`}>
                                    <TextPreProcessor text={item.value}/><ImageContent images={item.images}
                                                                                       height={item.imagesHeight}/>
                                </div>)}

                        {columns.length === (columnIdx + 1) && <DetailsMain details={mainDetails}/>}
                    </div>
                )
            )}
            </div>
        </>
    )
}

function DetailsMain({ details }) {
    let detailsArray = CmsHelper.getDetails(details);

    return (
        <div className="exclusive show-on-expand">
            {detailsArray.filter(item => CmsHelper.isHeader(item))
                .map((item, idx) => <span key={idx}>{item.substring(item.indexOf("**") + 2, item.lastIndexOf("**"))}</span>)
            }
            <ul>
                {detailsArray
                    .filter(item => !CmsHelper.isHeader(item))
                    .map((item, idx) => <li key={idx}>{item}</li>)}
            </ul>
        </div>
    );
}

function ImageContent({ images, height }) {
    if (images)
        return (
            <>
                <br />
                {
                    images.map((item, index) => {
                        return <img key={index} src={CmsHelper.getImagePath(item)} alt="product content logo" height={height} />
                    })
                }
            </>
        )

    return <></>
}

function Disclaimer({ disclaimers }) {
    if (disclaimers) {
        return disclaimers.value.map((item, index) => item && (<p key={index} className="disclaimer">{item.value}</p>))
    }
}

function MigrateActions({ onCancelSubscription, onNext, product }) {
    return (
        <div id="migration-buttons">
            <Button
                variant="outlined"
                color="primary"
                onClick={onCancelSubscription}
                id="cancel"
            >
                Δεν ενδιαφέρομαι
            </Button>
            <Button
                variant="contained"
                color="primary"
                onClick={() => onNext(product)}
                id="migrate"
            >
                Το θέλω!
            </Button>
        </div>
    );
}

export default ProductContainer;