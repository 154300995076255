import React from "react";
import "./Preview.css";
import CarouselDot from "../ProductTabs/CarouselDot/CarouselDot";
import CmsHelper, {LandingPageHelper} from "../../Utilities/CmsHelpers";
import {useCms} from "../../../context/CmsContext";

const borderColors = ["#FF5500", "#9735F1", "#0087FF", "#F3BF00"];

function Preview() {
    const {landingData } = useCms();
    const previewData = LandingPageHelper.getPreviewSection(landingData);

    const imgs = [];

    previewData.images && previewData.images.map((item, index) => imgs.push(Image(CmsHelper.getImagePath(item.image.data), item.alt, index)));

    return (<>
        {(!!previewData.title || !!imgs.length) &&
            <div className="preview-wrapper">
                {!!previewData.title &&
                    <div className="preview-header">
                        <img src={CmsHelper.getImagePath(previewData.logo)}
                             alt="ant1 plus preview"
                             className="preview-header-logo"/>
                        <h1 className="preview-header-text">{previewData.title}</h1>
                    </div>
                }
                {!!imgs.length &&
                    <div className="preview-body">
                        {imgs.map((item, index) => (<div className="preview-body-item-wrapper" key={index}>{item.img}</div>))}
                        <div className="preview-item-mobile">
                            <CarouselDot slides={imgs}/>
                        </div>
                    </div>
                }
            </div>
        }
    </>);
}

function Image(imagePath, imageMsg, index) {
    const colorIndex = index > borderColors.length ? index % borderColors.length : index;

    return {
        img: (<div className="preview-item">
                <img className="preview-body-imgs" src={imagePath} alt={imageMsg}
                     style={{borderColor: borderColors[colorIndex]}}/>
            </div>), id: (index++)
    };

}

export default Preview;
