export const ScreenHelper = {
    getScreenSize: () => {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    },

    updateScreemSize: (setScreenSize) => {
        window.onresize = () => {
            setScreenSize(ScreenHelper.getScreenSize());
        };
    },

    initRenderingType: () => {
        return ScreenHelper.getScreenSize().width<600 
            ? "Mobile" 
            : "Desktop";
    },

    getRenderingType: (setRenderingType) => {
        window.onresize = () => {
            setRenderingType(ScreenHelper.initRenderingType());
        };
    },
    initLandingRenderingType: () => {
        return ScreenHelper.getScreenSize().width<=450
            ? "Mobile"
            : "Desktop";
    },

    getLandingRenderingType: (setRenderingType) => {
        window.onresize = () => {
            setRenderingType(ScreenHelper.initLandingRenderingType());
        };
    }
}