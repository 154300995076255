import React, { useEffect, useRef, useState } from "react";
import "./ProductCard.css";
import { Button } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PopUp from "../../../Utilities/PopUp";
import CmsHelper, {LandingPageHelper, PickProgramPageHelper} from "../../../Utilities/CmsHelpers";
import TextPreProcessor from "../../../Utilities/TextPreProcessor";
import {useCms} from "../../../../context/CmsContext";

function ProductCard({
  product,
  cardAction,
  free,
  onCardClick,
  activeId,
  onClickOutside,
  cmsProductData,
  cmsProductDetails,
    tablet= false
}) {
  const [buttonVariant, setButtonVariant] = useState("outlined");
  const [expandList, setExpandList] = useState(false);
  const [openVillagePopUp, setOpenVillagePopUp] = useState(false);

  const ref = useRef(null);

  const programContent = LandingPageHelper.getProgram(cmsProductData.programs, product.displayName);

  const programDetails = LandingPageHelper.getProgramDetails(cmsProductDetails, product.displayName);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  const onExpandUSPList = () => {
    let value = expandList;
    setExpandList(!value);
  };

  const onHandleVillagePopUp = () => {
    let value = !openVillagePopUp;
    setOpenVillagePopUp(value);
  };

  return (
    programContent &&
    <div className={`product-card-external ${tablet ? "product-card-external-tablet":""}`}
         onMouseEnter={() => setButtonVariant("contained")}
         onMouseLeave={() => setButtonVariant("outlined")}
         id={product.displayName}>
      <div
        className={`product-card ${activeId.productId === product.productId &&
          activeId.pricePoint === product.pricePoint
          ? "active"
          : ""
          }`}
        onClick={() => {
          onCardClick(product);
        }}
        ref={ref}
      >
        {programContent.borderMsg && (
          <div className="message-box-container">
            <div className="message-box">{programContent.borderMsg}</div>
          </div>
        )}

        <div className="product-card-wrapper">
          <div className="title">
            <div>
              {programContent.title}{" "}
              <br />
              <div className="light">{programContent.secondaryTitle}</div>
            </div>
            {programContent.duration && (
                <div className={"duration ".concat(PickProgramPageHelper.getColorClass(programContent.duration))}>
                  <span className="duration-break-landing">{programContent.duration}</span>
                </div>
            )}
          </div>
          <div className={"product-card-content"}>
            <ProductContent active={expandList} children={programDetails?.info} />
            <div className={"product-card-content-lower"}>
              <div className="message">
                <img src="images/plus-sign.png" alt="ant1 plus sign" />
                {programContent.message}
              </div>

              <div className="bottom-section">
                <PriceInfo programContent={programContent} />
                <Button
                  variant={buttonVariant}
                  color="primary"
                  onClick={() => cardAction(product, free)}
                >
                  {free ? "Δωρεάν εγγραφή" : "Το θέλω"}
                </Button>

                {product.displayName === "Village" && (
                  <Button
                    variant="text"
                    color="primary"
                    className="more-button"
                    onClick={onHandleVillagePopUp}
                  >
                    Μάθε περισσότερα
                  </Button>
                )}
                {(programDetails?.info?.some(item => item.showOnExpand === true)) &&
                  <Button
                    variant="text"
                    color="primary"
                    className={`more-button-mobile ${expandList ? "active" : ""}`}
                    endIcon={<ArrowDownwardIcon id="arrow-icon" />}
                    onClick={onExpandUSPList}
                  >
                    Μάθε περισσότερα
                  </Button>
                }
              </div>
            </div>
          </div>
        </div>
        <PopUp
          openPopUp={openVillagePopUp}
          setOpenPopUp={setOpenVillagePopUp}
          maxWidth="md"
          showCloseButton={true}
        >
          <div id="village-pop-up-content">
            <div className="logos">
              <img src="images/ant1Logo.png" alt="ant1 plus logo" />
              <img
                src="images/VILLAGE CINEMAS_LOGO Color White 2 - big.png"
                alt="village logo"
                className="village"
              />
            </div>
            <h1>Με ΑΝΤ1+ πας σινεμά κάθε μήνα!</h1>
            <h3>
              Το ΑΝΤ1+ απογειώνει την ψυχαγωγία σου <br /> και σε στέλνει στα
              Village Cinemas!
            </h3>
            <p>
              Τώρα κάθε μήνα σε περιμένει αυτόματα στον λογαριασμό σου 1 εισιτήριο
              για τα Village Cinemas, για να απολαμβάνεις κινηματογραφικές
              υπερπαραγωγές μαζί με όλο το περιεχόμενο του ΑΝΤ1+
            </p>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenVillagePopUp(false);
                cardAction(product, free);
              }}
            >
              Ξεκίνα τώρα στο ΑΝΤ1+ Village Cinemas
            </Button>
          </div>
        </PopUp>
      </div>
    </div>
  );
}

const convertPriceToText = (price) => {
  return price.toString().replace(".",",");
}

function PriceInfo({ programContent }) {
  return (
    <div className={`price ${!(programContent.priceDescriptionNoDiscount || programContent.priceDescription || programContent.actualPrice || programContent.priceNoDiscount || programContent.actualPrice) ? "price-empty":"" }`}>
      {programContent.priceDescriptionNoDiscount ? <span className="cross light">{programContent.priceDescriptionNoDiscount}€</span> : null}
      {programContent.priceDescription ? <span>{programContent.priceDescription}&nbsp;</span> : null}
      {programContent.actualPrice ? <><span className="light">{programContent.priceDetails}</span> <br />{" "}</>: null}
      {programContent.priceNoDiscount ? <span className="cross">{convertPriceToText(programContent.priceNoDiscount)}€</span> : null}
      {programContent.actualPrice ? <><span className="bold">{convertPriceToText(programContent.actualPrice)}€</span> / {programContent.paymentDurationDescription}</> : null}
    </div>
  );
}

export function ProductContent({ active, children }) {

  const { landingRenderingType: renderingType } = useCms();

  if(active || renderingType === "Desktop" || children?.some(item => item.showOnExpand === false))
  return <ul className={`${active || renderingType === "Desktop" ? "desktop": ""} expand`}>
    {
      children?.map((item, index) => {
        if ((item.type === "All" || item.type === renderingType) && (!item.showOnExpand || active || renderingType === "Desktop"))
          return (
              <li key={index}>
                <TextPreProcessor text={item.value} />
                <ImageContent images={item.images.data} height={item.imagesHeight} />
              </li>
          )

        return null;
      })
    }
  </ul>

  return (<></>);
}

function ImageContent({ images, height }) {
  if (images)
    return (
      <>
        <br />
        {
          images.map((item, index) => {
            return <img key={index} src={CmsHelper.getImagePath(item)} alt="product content logo" height={height} />
          })
        }
      </>
    )

  return <></>
}

export default ProductCard;
