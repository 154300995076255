import React, { useEffect, useState } from "react";
import "./ProductTabs.css";
import {Box} from "@mui/material";
import ImageSlider from "./ImageSlider/ImageSlider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CarouselDot from "./CarouselDot/CarouselDot";
import Carousel from "react-spring-3d-carousel";
import CmsHelper, { LandingPageHelper } from "../../Utilities/CmsHelpers";
import {styled} from "@mui/system";
import {useCms} from "../../../context/CmsContext";


const CustomTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .MuiTab-root": {
    color: "rgba(255, 255, 255, 0.9)",
    textTransform: "capitalize",
    borderBottom: "4px solid transparent",
    padding: "8px",
    minHeight: "29px",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "145%",
    letterSpacing: "-0.015em",
  },
  "& .MuiTab-root.Mui-selected": {
    color: "rgba(255, 255, 255, 0.9)",
    borderColor: "#FF0078",
    fontWeight: "700",
  },
  "& .MuiTabs-scroller": {
    borderBottom: "1px solid rgba(255, 255, 255, 0.25)",
  },
  color: "white",
  "& .MuiTabs-flexContainer": {
    justifyContent: "center",
  },
  [theme.breakpoints.up("sm")]: {
    "& .MuiTab-root": {
      fontSize: "24px",
      lineHeight: "120%",
    },
    "& .MuiTabs-scroller": {
      borderBottom: "none",
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="tab-panel"
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ProductTabs() {
  const { renderingType } = useCms();
  // const classes = useStyles(theme);
  const [value, setValue] = useState(0);
  const mediaMatch = window.matchMedia("(min-width: 950px)");
  const [matches, setMatches] = useState(mediaMatch.matches);
  const {landingData} = useCms();
  const productTabTitle = LandingPageHelper.getProductTabsTitle(landingData, renderingType);
  const productTabData = LandingPageHelper.getProductTabsSection(landingData);

  useEffect(() => {
    const handler = (e) => {
      setMatches(e.matches);
    };
    mediaMatch.addEventListener("change", handler);
    return () => mediaMatch.removeEventListener("change", handler);

  });

  const handleTabChange = (e, newValue) => {
    setValue(newValue);
  };

  return (<>
        {(!!productTabData.length || !!productTabTitle.title) &&
            <div className="product-tabs-wrapper">
              {!!productTabTitle.title &&
                  <h1>
                    {CmsHelper.getDetails(productTabTitle.title).map((item, index) => {
                      return <span key={index}>{item} <br/> </span>
                    })}
                  </h1>
              }
              {!!productTabData.length &&
                  <div id="tabs-container">
                    <CustomTabs value={value}
                          onChange={handleTabChange}
                          aria-label="ant1 products tabs"
                          // className={classes}
                          variant={matches ? "standard" : "scrollable"}>
                      {productTabData.map((item, index) => {
                          return (
                            <Tab key={index} label={item.header} {...a11yProps(index)} />
                          )
                        })
                      }
                    </CustomTabs>
                    {productTabData.map((item, index) => {
                      return <TabPanel key={index} value={value} index={index}>
                        <TabContent content={item} index={index} />
                      </TabPanel>
                    })}
                  </div>
              }
            </div>
        }
        </>
  );
}

export default ProductTabs;

function createSlides(images, imageSlider, changeSlide) {
  let slides = [];

  if (images) {
    images.forEach((img, index) => {
      if (imageSlider)
        slides.push({
          key: "slide-" + index,
          content: (
            <img
              src={CmsHelper.getImagePath(img)}
              alt="Product slider"
              onClick={() => {
                changeSlide(index);
              }}
            />
          ),
        })
      else {
        let path = CmsHelper.getImagePath(img);
        if (path != null) {
          slides.push({
            img: <img key={index} src={path} alt="Product slides" />,
            id: index,
          },)
        }
      }
    })
  }

  return slides;
}

function TabContent({ content, index }) {
  const minSwipeDistance = 50;

  const [goToSlide, setGoToSlide] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const changeSlide = (id) => {
    setGoToSlide(id);
  };

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  let imageSlider = content.imageSlider === true;
  const slides = createSlides(content.images.data, imageSlider, changeSlide)

  const onTouchEnd = (id) => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe) {
      if (id === slides.length) {
        setGoToSlide(0);
      } else {
        setGoToSlide(id + 1);
      }
    }
    if (isRightSwipe) {
      if (id === slides.length) {
        setGoToSlide(slides.length);
      } else {
        setGoToSlide(id - 1);
      }
    }
  };

  return (
    <div className="tab-content originals">
      {(imageSlider)
          ? (
            <>
              <div
                className="tabs-carousel-mobile"
                style={{ maxWidth: "350px", height: "350px", margin: "auto" }}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={() => {
                  onTouchEnd(goToSlide);
                }}
              >
                <Carousel
                  slides={slides}
                  goToSlide={goToSlide}
                  showNavigation={false}
                />
              </div>
              <div className="tabs-carousel-desktop">
                <ImageSlider images={content.images.data} />
              </div>
            </>
          ) : <CarouselDot slides={slides} />
      }

      <div className="description originals">
        <img className="logo" src={CmsHelper.getImagePath(content.logo)} alt="logo" />
        <h2>{content.title}</h2>
        <p>{content.details}</p>
      </div>

    </div>
  );
}