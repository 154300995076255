import React from "react";

const TextPreProcessor = ({ text }) => {
    const parts = text.split(/sup_([^_]+)_sup/g);

    return (
        <span>
      {parts.map((part, index) => {
          if (index % 2 !== 0) {
              return <sup key={index}>{part}</sup>;
          } else {
              return part;
          }
      })}
    </span>
    );
};

export default TextPreProcessor;