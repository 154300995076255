import React, { useEffect, useRef, useState } from "react";
import "./ProductCardHorizontal.css";
import { Button } from "@mui/material";
import { LandingPageHelper } from "../../../Utilities/CmsHelpers";
import { ProductContent } from "../ProductCard/ProductCard";

function ProductCardHorizontal({
  // info,
  product,
  cardAction,
  free,
  onCardClick,
  onClickOutside,
  activeId,
  cmsProductData,
  cmsProductDetails
}) {
  const [buttonVariant, setButtonVariant] = useState("outlined");

  const ref = useRef(null);

  const programContent = LandingPageHelper.getProgram(cmsProductData.programs, product.displayName)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <div className="product-card-horizontal-external"
         onMouseEnter={() => setButtonVariant("contained")}
         onMouseLeave={() => setButtonVariant("outlined")}>
      <div
        className={`product-card-horizontal ${activeId.productId === product.productId &&
          activeId.pricePoint === product.pricePoint
          ? "active"
          : ""
          }`}
        onClick={() => onCardClick(product)}
        ref={ref}
      >
        <div className="product-card-horizontal-wrapper">
          <div className="head">
            <div className="title">
              <div>
                {programContent.title}{" "}
                <br />
                <div className="light">{programContent.secondaryTitle}</div>
              </div>
            </div>
            <div className="message">
              <img src="images/plus-sign.png" alt="ant1 plus sign" />
              {programContent.message}
            </div>
          </div>

          <ProductContent children={LandingPageHelper.getProgramDetails(cmsProductDetails, product.displayName)?.info} />

          <div className="buttons">
            <Button
              variant={buttonVariant}
              color="primary"
              onClick={() => cardAction(product, free)}
            >
              {free ? "Δωρεάν εγγραφή" : "Το θέλω"}
            </Button>

            {product.displayName === "Village" && (
              <Button variant="text" color="primary" className="more-button">
                δωρεάν εγγραφή
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCardHorizontal;
