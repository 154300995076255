import React, { useEffect, useState } from "react";
import "./ProductCards.css";
import ProductCard from "./ProductCard/ProductCard";
import ProductCardHorizontal from "./ProductCardHorizontal/ProductCardHorizontal";
import UserService from "../../../services/UserService";
import { useNavigate } from "react-router-dom";
import { LandingPageHelper } from "../../Utilities/CmsHelpers";
import {useCms} from "../../../context/CmsContext";
import {useAuth} from "../../../context/AuthContext";
import {useAccount} from "../../../context/AccountContext";

function ProductCards({ products }) {
  const mediaMatchLG = window.matchMedia("(min-width: 1167px)");
  const [matchesLG, setMatchesLG] = useState(mediaMatchLG.matches);
  let navigate = useNavigate();

  let account = useAccount();

  const [activeCard, setActiveCard] = useState({});

  const  {setCheckLoggedIn} = useAuth()

  const {landingData} = useCms();
  const productData = LandingPageHelper.getProductSection(
    landingData
  );

  const productDetailsData = LandingPageHelper.getProductDetailsSection(
    landingData
  );
  const productDisclaimers = LandingPageHelper.getProductDisclaimersSection(
    landingData
  );
  const disclaimersToShow = productDisclaimers.filter((item) =>
    products.find(
      (product) =>
        product.displayName === item.display_name.data.attributes.value
    )
  );

  useEffect(() => {
    const handler = (e) => {
      setMatchesLG(e.matches);
    };
    mediaMatchLG.addEventListener("change", handler);
    return () => mediaMatchLG.removeEventListener("change", handler);
  });

  const cardAction = (product, free) => {
    sessionStorage.setItem("selectedProgramId", JSON.stringify(product));
    account.clearSessionCoupon();
    if (UserService.isLoggedIn()) {
      setCheckLoggedIn(true);
      navigate("/PickProgram");
    } else {
      if (free) {
        UserService.doRegister(
          process.env.REACT_APP_START_FOR_FREE_REDIRECT_URI,
          account.language
        );
      } else {
        UserService.doRegister(
          process.env.REACT_APP_START_FOR_NOTFREE_REDIRECT_URI,
          account.language
        );
      }
    }
  };

  const onCardSelect = (product) => {
    setActiveCard(product);
  };

  const onClickOutside = () => {
    setActiveCard({});
  };

  const productsExist = products && products.length > 0;

  // we use this to show content that relates to promoting migration from ANT1 FIFA to Formula1
  const fifaShadowOffer =
    products.length === 1 &&
    ["F1shadow", "F1offer9", "F1offer20"].includes(products[0].displayName);

  return (
    <React.Fragment>
      {productsExist && (
        <div id="product-cards">
          <h2>
            {
              LandingPageHelper.getProductTtitle(productData, fifaShadowOffer)
                .header
            }
          </h2>

          <div id="cards">
            {matchesLG ? (
                <>
                  <div className="vertical">
                    {products.map((product, index) => {
                      if (product.displayName !== "Lite") {
                        return (
                          <ProductCard
                            key={index}
                            product={product}
                            cardAction={cardAction}
                            id={product.displayName}
                            onCardClick={() => {
                              onCardSelect(product);
                            }}
                            onClickOutside={onClickOutside}
                            activeId={activeCard}
                            cmsProductData={productData}
                            cmsProductDetails={productDetailsData}
                          />
                        );
                      } else {
                        return <React.Fragment key={index}></React.Fragment>;
                      }
                    })}
                  </div>
                  {products
                    .filter((product) => product.displayName === "Lite")
                    .map((element, index) => {
                      return <ProductCardHorizontal
                          key={index}
                          cardAction={cardAction}
                          product={element}
                          free={true}
                          id={element.displayName}
                          onCardClick={onCardSelect}
                          onClickOutside={onClickOutside}
                          activeId={activeCard}
                          cmsProductData={productData}
                          cmsProductDetails={productDetailsData}
                      />
                    })}
                </>
                ):(
                <>
                  <div className="vertical">
                    {products.map((product, index) => {
                      if (product.displayName !== "Lite") {
                        return (
                            <ProductCard
                                key={index}
                                product={product}
                                cardAction={cardAction}
                                id={product.displayName}
                                onCardClick={() => {
                                  onCardSelect(product);
                                }}
                                onClickOutside={onClickOutside}
                                activeId={activeCard}
                                cmsProductData={productData}
                                cmsProductDetails={productDetailsData}
                            />
                        );
                      } else {
                        return <React.Fragment key={index}></React.Fragment>;
                      }
                    })}
                    {products
                        .filter((product) => product.displayName === "Lite")
                        .map((element, index) => {
                          return <ProductCard
                              key={index}
                              cardAction={cardAction}
                              product={element}
                              free={true}
                              tablet={true}
                              id={element.displayName}
                              onCardClick={onCardSelect}
                              onClickOutside={onClickOutside}
                              activeId={activeCard}
                              cmsProductData={productData}
                              cmsProductDetails={productDetailsData}
                          />
                        })}
                  </div>
                </>)
            }
          </div>
          <div id="products-info">
            {disclaimersToShow.map((d) => {
              return d.value.map((item, idx) => {
                return <p key={idx}>{item.value}</p>;
              });
            })}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}



export default ProductCards;