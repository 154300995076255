import React, {useEffect, useState} from "react";
import "../GlobalStyles.css";
import "./Invoice.css";
import {useLocation, useNavigate} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Button} from "@mui/material";
import {useAuth} from "../../context/AuthContext";

function Invoice() {
    const location = useLocation();
    let navigate = useNavigate();

    const {setCheckLoggedIn} = useAuth()


    useEffect(() => {
        window.scrollTo(0, 0)
        if (!(!!location.state) || !(!!location.state.id)) {
            navigate("/")
        } else {
            setData(calculateValues(location.state.id))
        }
    }, [])//eslint-disable-line react-hooks/exhaustive-deps

    const [{
        formatted_paid_amount,
        payment_profile,
        quan,
        roundedNumber,
        per,
        fpaStart,
        payment,
        discounts,
        disbeforefpa,
        disfpa,
        formatted_disc_amount,
        creditbeforefpa,
        creditfpa,
        credits,
        refunds,
        paidamnoFPA,
        paidamFPA,
        formatted_total,
        product_name,
        number,
        issue_date,
        customerEmail
    }, setData] = useState({})


    const calculateValues = (id) => {
        var product_name = id.product_name;
        var number = id.number;
        var issue_date = id.issue_date;
        var payment_profile = id.payments;
        var discounts = id.discounts;
        var credits = id.credits;
        var refunds = id.refunds;
        var payment = id.line_items;
        var customerEmail = id.customer.email;

// Quantity decimal
        var quan = payment.length ? parseInt(payment[0].quantity) : 0;
// Percentage decimal
        var per = id.taxes.length ? parseInt(id.taxes[0].percentage) : 0;

// Conversion of vat
        var conper = 1 + per / 100;

// Without FPA total amounlt
        var startamount = parseFloat(payment[0].unit_price);
        var nofpa = startamount / conper;
        var roundedNumber = parseFloat(nofpa.toFixed(2));

// FPA value of the started price
        var fpaStart = (startamount - roundedNumber).toFixed(2);

// Discount price before fpa
        var disamount = discounts.length ? parseFloat(discounts[0].discount_amount) : 0;
        var disnofpa = disamount / conper;
        var disbeforefpa = parseFloat(disnofpa.toFixed(2));
        var disfpa = (disamount - disbeforefpa).toFixed(2);

// Credits
        var creditamount = credits.length ? credits[0].applied_amount : 0;
        var creditnofpa = creditamount / conper;
        var creditbeforefpa = parseFloat(creditnofpa.toFixed(2));
        var creditfpa = (creditamount - creditbeforefpa).toFixed(2);

// Convert vat string to vat number
//         var vatNumber = id.taxes.length ? parseInt(id.taxes[0].percentage) : 0;
        // var vatdiv = vatNumber / 100;
        // var fpa = (vatdiv * id.paid_amount).toFixed(2);

// Paid amount
        var paidAmount = parseFloat(id.paid_amount);
        var paidAmountnofpa = paidAmount / conper;
        var paidamnoFPA = ((!!refunds && !(!!(refunds?.length))) && !!credits && credits[0]?.applied_amount) ?
            parseFloat(((id.total_amount / conper).toFixed(2) - creditbeforefpa)).toFixed(2):
            parseFloat(paidAmountnofpa.toFixed(2)).toFixed(2)

        var paidamFPA = ((!!refunds && !(!!(refunds?.length))) && !!credits && credits[0]?.applied_amount) ?
            (Math.abs((parseFloat(id.total_amount) - (parseFloat(id.total_amount) / conper)) - creditfpa)).toFixed(2) :
            (paidAmount - paidamnoFPA).toFixed(2)


// Due amount formatting
        var total = parseFloat(id.due_amount);
        var formatted_total = total.toFixed(2);

// Paid amount formatting
        var formatted_paid_amount = ((!!refunds && !(!!(refunds?.length))) && !!credits && credits[0]?.applied_amount) ?
            (id.total_amount - credits[0]?.applied_amount).toFixed(2):
            paidAmount.toFixed(2);

// Discount amount formatting
        var formatted_disc_amount = disamount.toFixed(2);

        return {
            formatted_paid_amount,
            payment_profile,
            quan,
            roundedNumber,
            per,
            fpaStart,
            payment,
            discounts,
            disbeforefpa,
            disfpa,
            formatted_disc_amount,
            creditbeforefpa,
            creditfpa,
            credits,
            refunds,
            paidamnoFPA,
            paidamFPA,
            formatted_total,
            product_name,
            number,
            issue_date,
            customerEmail
        }
    }
    const onGoBack = () => {
        setCheckLoggedIn(false);
        navigate("/Account");
    };

    return (
        <>
            <div className={"invoice-header-wrapper"}>
                <Button
                    variant="text"
                    className="blue questionnaire-goBack"
                    id="goBackButton"
                    onClick={onGoBack}>
                    <ArrowBackIcon />
                    Πίσω
                </Button>
                <button className={"invoice-download-btn"} onClick={()=>{
                    window.print();
                }}>Λήψη Παραστατικού</button>
            </div>
            <div className="main-page" id={"main-page"}>
                <div id="content" className="sub-page">
                    <div className="container-fluid px-0">
                        <section className="head">
                            <div className="container-fluid px-0">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="left_logo text-start">
                                            <div className="img_logo">
                                                <img className="w-100" src="images/ant1Logo-dark.png" alt=""/>
                                            </div>
                                            <div className="company_info">
                                                <p className="mt-4 mb-0 bold">Antenna TV B.V.</p>
                                                <p className="mb-0 bold">De Boelelaan 7 (5th floor)</p>
                                                <p className="mb-0 bold">1083 HJ Amsterdam</p>
                                                <p className="mb-0 bold">The Netherlands</p>
                                                <p className="mb-0 bold">Vat: NL851837359B01</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="right_info text-end">
                                            <div className="right_info_inner">
                                                <p className="bold mid">Invoice / Payment Receipt</p>
                                            </div>
                                            <div className="right_info_table">
                                                <div className="reciept">
                                                    <p className="mb-0">Receipt Number: #{number}</p>
                                                </div>
                                                <div className="issue">
                                                    <p className="mb-0">Issue Date: {issue_date}</p>
                                                </div>
                                            </div>
                                            <div className="right_paid_table d-flex justify-content-end">
                                                <div className="paid blue_bg w-33">
                                                    <p className="mb-0 bold white">PAID</p>
                                                </div>
                                                <div className="paid w-33">
                                                    {formatted_paid_amount} EUR
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="billing_info padding_top">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="billing_info_inner test-start">
                                            <h5>Billing Info:</h5>
                                            <span className="info bold">Email:  </span>{customerEmail}<br/>
                                            <span
                                                className="info bold"> Payment Info:</span> {!!payment_profile ? payment_profile[0]?.payment_method?.card_brand : null} {!!payment_profile ? payment_profile[0]?.payment_method?.masked_card_number : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="payment_info mt-5">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="payment_info_inner">
                                            <table border="0">
                                                <tbody>
                                                <tr>
                                                    <td className="text-center bold grey-bg">Product</td>
                                                    <td className="text-center bold grey-bg">Quantity</td>
                                                    <td className="text-center bold grey-bg">Amount</td>
                                                    <td className="text-center bold grey-bg">VAT(%)</td>
                                                    <td className="text-center bold grey-bg">Vat Price</td>
                                                    <td className="text-center bold grey-bg">Unit Price</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">{product_name}</td>
                                                    <td className="text-center">{quan}</td>
                                                    <td className="text-center">{roundedNumber}€</td>
                                                    <td className="text-center">{per}%</td>
                                                    <td className="text-center">{fpaStart}€</td>
                                                    <td className="text-center">{!!payment ? payment[0]?.unit_price + "€" : null}</td>
                                                </tr>
                                                {!!discounts && discounts[0]?.discount_amount && <tr>
                                                    <td className="text-center">{discounts[0].title}</td>
                                                    <td className="text-center">{quan}</td>
                                                    <td className="text-center">-{disbeforefpa}€</td>
                                                    <td className="text-center">{per}%</td>
                                                    <td className="text-center">-{disfpa}€</td>
                                                    <td className="text-center">-{formatted_disc_amount}€</td>
                                                </tr>}
                                                {((!!refunds && !(!!(refunds?.length))) && !!credits && credits[0]?.applied_amount) && <tr>
                                                    <td className="text-center">Credit Proration</td>
                                                    <td className="text-center">{quan}</td>
                                                    <td className="text-center">-{creditbeforefpa}€</td>
                                                    <td className="text-center">{per}%</td>
                                                    <td className="text-center">-{creditfpa}€</td>
                                                    <td className="text-center">-{credits[0]?.applied_amount}€</td>
                                                </tr>}
                                                <tr className="no-border position-relative">
                                                    <td></td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr className="last">
                                                    <td className="text-center bold">Total</td>
                                                    <td>&nbsp;</td>
                                                    <td className="text-center bold">{paidamnoFPA}€</td>
                                                    <td className="text-center bold">{per}%</td>
                                                    <td className="text-center bold">{paidamFPA}€</td>
                                                    <td className="text-center bold">{formatted_paid_amount}€</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="billing_period xl_padding_top">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="billing_period_inner text-start">
                                            <span
                                                className="info bold">Billing Period: {!!payment ? payment[0]?.description : null}</span><br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="ammount_due mt-5">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="ammount_due_inner w-50">
                                            <table className="w-100">
                                                <tbody>
                                                <tr>
                                                    <td className="text-center bold">AMOUNT DUE</td>
                                                    <td className="text-center bold">{formatted_total}€</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>);
}

export default Invoice;
