import "./textbox.css"
import React, {useEffect, useRef, useState} from "react";
function TextBox({fieldTitle, fieldName, titleInfo, type = "text", options, redirectEdit, placeholder, onChange, hasIcon, iconAlt, iconOnClick, defaultValue, disabled = false, isError}){
    const [currentSelectedValue, setCurrentSelectedValue] = useState(options? options[0]:null)

    const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(() => {
        if (type === "phoneNumber" && defaultValue) {
            // console.log(defaultValue)
            options.map((item, index)=> defaultValue.startsWith(item.code) ? setPhoneNumber(formatPhoneNumber(defaultValue.slice(defaultValue.indexOf(item.code)+item.code.length))) : null)
        }
        if(defaultValue === ""){
            setPhoneNumber("")
        }
    }, [defaultValue]); //eslint-disable-line react-hooks/exhaustive-deps


    useEffect(()=>{
        if(type === "phoneNumber")
        if(defaultValue){
            setCurrentSelectedValue(options.filter(item => defaultValue.startsWith(item.code))[0])
        }else{
            setCurrentSelectedValue(options? options[0]:null)
        }
    }, []) //eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (value) => {
        if(value.length){
            const input = value.replace(/\D/g, ''); // Remove non-numeric characters
            const formattedNumber = formatPhoneNumber(input);
            setPhoneNumber(formattedNumber);
            onChange(currentSelectedValue.code+trimNumber(input));
        }else{
            setPhoneNumber('');
            onChange(currentSelectedValue.code+'');
        }
    };

    const formatPhoneNumber = (input) => {
        if(input.length){
            let formattedNumber = '';
            for (let i = 0; i < input.length; i++) {
                if (i === 3 || i === 6) {
                    formattedNumber += ' '; // Add space after the 3rd and 6th character
                }
                formattedNumber += input[i];
            }
            return trimNumber(formattedNumber, 2);
        }else{
            return '';
        }
    };

    const trimNumber = (number, whitespace=0)=>{
        if(number.length){
            if(currentSelectedValue.text === "GR"){
                return number.trim().substring(0, 10+whitespace); // Trim extra characters and set max length
            }else if (currentSelectedValue.text === "CY"){
                return number.trim().substring(0, 8+whitespace); // Trim extra characters and set max length
            }
        }else{
            return ''
        }
    }

    const [isOpen, setIsOpen] = useState(false)

    const trigger = useRef(null)
    const menu = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!menu?.current?.contains(event.target) && !trigger?.current?.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [trigger, menu])


    const [isVisible, setIsVisible] = useState(false);

    const showTooltip = () => {
        setIsVisible(true);
    };

    const hideTooltip = () => {
        setIsVisible(false);
    };

    return(<div className={"textbox-wrapper"}>
        <div className={"textbox-top"}>
            <div className={"textbox-title-wrapper"} >
                <span className={"textbox-title"}>{fieldTitle}</span>
                {!!titleInfo &&
                    <div className={"textbox-title-wrapper-tooltip"} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
                        <img className={"textbox-title-wrapper-tooltip-image"} src={"images/account/fieldTitleInfo.svg"} alt={"info"}/>
                        {isVisible &&
                            <div className={"textbox-title-wrapper-tooltip-wrapper"}>
                                <img src={"images/account/tooltipArrowIcon.svg"} alt={""}/>
                                {titleInfo}
                            </div>
                        }
                    </div>
                }
            </div>
            {type === "phoneNumber" &&
                <span className={"textbox-informationText"}>(Απαιτείται κωδικός επιβεβαίωσης)</span>
            }
            {!!redirectEdit &&
                <span className={"textbox-redirectEdit"} onClick={()=>{redirectEdit()}}>Επεξεργασία</span>
            }
        </div>
        <div className={`textbox-bot ${(disabled || !!redirectEdit) ? "disabled" : ""} ${isError ? "textbox-bot-error" : ""}`}>
            {hasIcon &&
                <img src={hasIcon} alt={iconAlt} className={`${!!iconOnClick ? "isClickable" : ""}`} onClick={()=>{if(!!iconOnClick){
                    iconOnClick()
                }}}  />
            }
            {type==="phoneNumber" ?
                <div className={"phoneNumber-wrapper"}>
                    <div className={"phoneNumber-selectBox-wrapper"}>
                        <div className={`phoneNumber-selectBox ${(disabled || !!redirectEdit) ? "disabled" : ""}`} onClick={() => {if (!disabled) setIsOpen(prev => !prev)}} ref={trigger}>
                            <div className={"phoneNumber-selectBox-text-wrapper"}>
                                <span className={"phoneNumber-selectBox-text"}>{currentSelectedValue?.text}</span>
                                {isOpen && <img src={`images/account/dropDownIconUp.svg`}
                                                alt={'arrow drop up'} width={24} height={24}/>}
                                {!isOpen && <img src={`images/account/dropDownIconDown.svg`}
                                                 alt={'arrow drop down'} width={24} height={24}/>}
                            </div>
                            <span className={"phoneNumber-selectBox-code"}>{currentSelectedValue?.code}</span>
                        </div>
                        <input type={"text"} name={fieldName} className={`textbox-input ${disabled ? "textbox-input-disabled" : ""}`} placeholder={placeholder} onChange={(e)=>{handleChange(e.target.value)}} value={phoneNumber} disabled={disabled || !!redirectEdit}/>
                    </div>
                    {isOpen &&
                        <ul className={"phoneNumber-dropdownMenu"}
                            ref={menu}>
                            {options.filter(item => item.code !== currentSelectedValue?.code).map((item, index) =>
                                <li key={index}
                                    className={"phoneNumber-dropdownMenu-item"}
                                    onClick={() => {
                                        setCurrentSelectedValue(item)
                                        handleChange(phoneNumber);
                                        setIsOpen(false);
                                    }}>
                                    <span>{item.text}</span>
                                    {/*<span>{item.code}</span>*/}
                                </li>
                            )}
                        </ul>
                    }
                </div>
                :
                <input type={type} name={fieldName} className={`textbox-input ${disabled ? "textbox-input-disabled" : ""}`} placeholder={placeholder} onChange={onChange} value={defaultValue} disabled={disabled || !!redirectEdit}/>
            }
            {(!(disabled || !!redirectEdit) && isError) &&
                <div className={"textbox-error"}>
                    <img src={"images/account/textboxErrorIcon.svg"}  alt={""}/>
                    <span>Το τηλέφωνο δεν είναι έγκυρο</span>
                </div>
            }
        </div>
    </div>)
}

export default TextBox;