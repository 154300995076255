export const formatDate = (dateString) => {
    if(dateString){
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }else{
        return '';
    }
};

export const gradientGetter = (product)=> {
    if(product){
        if (product.startsWith("ANT1+ Village")) {
            return "account-village"
        }else if (product.startsWith("ANT1+ Value Pack")) {
            return "account-valuepack"
        }else if (product.startsWith("ANT1+ Free")) {
            return "account-free"
        }else if (product.startsWith("ANT1+")) {
            return "account-plus"
        }
    }
    return "account-free"
}