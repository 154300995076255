export const ProgramBannersHelper = {
  PAGE_URL: "/product-banners",

  getGenericBanner: (data, type, device) => {
    if (data)
      return data.find(
        (item) => 
          item.active 
            && (item.type?.toLowerCase() === type.toLowerCase() || item.type === 'All')
            && (item.deviceType?.toLowerCase() === device.toLowerCase() || item.deviceType === 'All')
            && item.display_name === null
      );

    return {};
  },

  getBanner: (data, type, device, display_name) => {
    if (data)
      return data.find(
        (item) => 
          item.active 
            && (item.type?.toLowerCase() === type.toLowerCase() || item.type === 'All')
            && (item.deviceType?.toLowerCase() === device.toLowerCase() || item.deviceType === 'All')
            && item.display_name?.value === display_name
      );

    return {};
  },

};

export const PickProgramPageHelper = {
  PAGE_URL: "/ant1-PIck-programs",

  getColorClass: (durationDescr) => {
    if (durationDescr) {
      return durationDescr.indexOf("9μηνη") > -1
        ? "blue"
        : durationDescr.indexOf("12μηνη") > -1
        ? "red"
        : durationDescr.indexOf("μηνιαία") > -1
        ? "purple"
        : "";
    }

    return "";
  },

  getPrograms: (pickProgram, type) => {
    if (pickProgram)
      return pickProgram.find(
        (item) => item.type?.toLowerCase() === type?.toLowerCase()
      );

    return {};
  },

  getProgramInfo: (programInfo) => {
    let programData = [];
    let programDetails = [];
    let programDisclaimers = [];
    if (programInfo) {
      programData = programInfo.programs;
      programDetails = programInfo.programDetails;
      programDisclaimers = programInfo.programDisclaimers;
    }

    return { programData, programDetails, programDisclaimers };
  },

  getProgram: (programInfo, displayName) => {
    if (programInfo && programInfo.programData && displayName) {
      return programInfo.programData.find(
        (item) =>
          item.display_name.value.toLowerCase() === displayName.toLowerCase()
      );
    }

    return {};
  },

  getProgramDetails: (programInfo, displayName) => {
    if (programInfo && programInfo.programDetails && displayName) {
      return programInfo.programDetails.find(
        (item) =>
          item.display_name.value.toLowerCase() === displayName.toLowerCase()
      );
    }

    return {};
  },

  getProgramDisclaimers: (programInfo, displayName) => {
    if (programInfo && programInfo.programDisclaimers && displayName) {
      return programInfo.programDisclaimers.find(
        (item) =>
          item.display_name.value.toLowerCase() === displayName.toLowerCase()
      );
    }

    return {};
  },
};

export const LandingPageHelper = {
  PAGE_URL: "/ant1-landing-page",

  getQuestionsSection: (data) => {
    if (data.data) return data.data.attributes.questions;

    return [];
  },

  getDevicesSection: (data) => {
    if (data.data) return data.data.attributes.devices;

    return {};
  },

  getBannerSection: (data) => {
    if (data.data) return data.data.attributes.banner;

    return {};
  },
  getInformationModal: (data) => {
    if (data.data) return data.data.attributes.informativeModal.filter(entry => entry.active);

    return [];
  },
  getProductTabsSection: (data) => {
    if (data.data) return data.data.attributes.productTabs;

    return [];
  },

  getProductTabsTitle: (data, appType) => {
    if (data && data.data)
      return data.data.attributes.productTabsTitles.find(
        (item) => appType.toLowerCase() === item.type.toLowerCase()
      );

    return {};
  },

  getBannerPricing: (pricing, displayName) => {
    if (pricing) {
      return pricing.find(
        (item) =>
          item.display_name.data.attributes.value.toLowerCase() ===
          displayName.toLowerCase()
      ) || pricing.find(
          (item) =>
              item.display_name.data.attributes.value.toLowerCase() ===
              "Generic".toLowerCase()
      );
    }

    return {};
  },

  getHeaderSection: (data, subscriptionType) => {
    if (data.data) {
      return data.data.attributes.header.find((item) => {
        return (
          item.subscriptionType.name.toLowerCase() ===
            subscriptionType.toLowerCase() && item.active === true
        );
      });
    }

    return {};
  },

  getPreviewSection: (data) => {
    if (data.data) {
      return data.data.attributes.preview;
    }

    return {};
  },

  getProductSection: (data) => {
    if (data.data) return data.data.attributes.ant1Programs;

    return {};
  },

  getProductDetailsSection: (data) => {
    if (data.data) return data.data.attributes.programDetails;

    return [];
  },

  getProductDisclaimersSection: (data) => {
    if (data.data) return data.data.attributes.programDisclaimers;

    return [];
  },

  getProductTtitle: (product, shadowOffer) => {
    if (product && product.mainTitle)
      return product.mainTitle.find((item) => {
        if (shadowOffer) return item.type === "ShadowOffers";
        return item.type === "Generic";
      });

    return {};
  },

  getProgram: (programs, displayName) => {
    if (programs && displayName) {
      return programs.find(
        (item) =>
          item.display_name.data.attributes.value.toLowerCase() ===
          displayName.toLowerCase()
      );
    }

    return {};
  },

  getProgramDetails: (programDetails, displayName) => {
    if (programDetails && displayName) {
      return programDetails.find(
        (item) =>
          item.display_name.data.attributes.value.toLowerCase() ===
          displayName.toLowerCase()
      );
    }

    return [];
  },
};

const CmsHelper = {
  REGISTRATION: "registration",
  UPGRADE_PROGRAM: "upgrade-program",
  CANCELLATION_MIGRATE: "cancellation-migrate",
  DEFAULT: "default",

  LandingPageHelper,
  PickProgramPageHelper,
  ProgramBannersHelper,

  isJsonEmpty: (obj) => {
    if (obj) return Object.keys(obj).length === 0;

    return true;
  },

  getDetails: (details) => {
    if (details) return details.split(/\r?\n/);

    return [];
  },

  isHeader: (text) => {
    return /^[**]/.test(text) && /[**]$/.test(text);
  },

  getImagePath: (imageData) => {
    if (imageData && imageData.data) return imageData.data.attributes.url;
    else if (imageData && imageData.attributes) return imageData.attributes.url;
    else if (imageData && imageData.url) return imageData.url;

    return null;
  },

  getImageData: (images, type) => {
    if (images) {
      let imageData = images.find(
        (item) => item.appType.toLowerCase() === type.toLowerCase()
      );

      if (imageData) return imageData.image;
    }

    return {};
  },
};

export const QuestionnaireHelper = {
  PAGE_URL: "/questionnaires",

  getQuestionnaire: (questionnaire, type) => {
    if (questionnaire)
      return questionnaire.find(
          (item) => item.Identifier?.toLowerCase() === type?.toLowerCase()
      );

    return {};
  },
};

export default CmsHelper;
