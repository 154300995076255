import React, { useState, useEffect } from "react";
import Program from "../Program/Program";
import "./PickProgram.css";
import { useLocation, useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import Loader from "../Loader/Loader";
import AccountsService from "../../services/AccountsService";
import AwsApiService from "../../services/AwsApiService";
import GoogleAnalytics from "../../analytics/GoogleAnalytics";
import CouponsService from "../../services/CouponsService";
import GenericError from "../Utilities/GenericError";
import PopUp from "../Utilities/PopUp";
import {useLoading} from "../../context/LoadingContext";
import {useAuth} from "../../context/AuthContext";
import {isValidPhoneNumber} from "react-phone-number-input/mobile";
import CardValidationService from "../../services/CardValidationService";
import jwt_decode from "jwt-decode";
import PinVerification from "../PinVerification/PinVerification";

function PickProgram() {
  let navigate = useNavigate();

  const {isLoading, addLoading, removeLoading} = useLoading();
  const {loggedIn} = useAuth()
  let location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const flow = queryParams.get("flow");
  const token = queryParams.get("token");//token from scheduled save desk

  const [verificationSent, setVerificationSent] = useState(false);

  const [couponInfo, setCouponInfo] = useState({});
  const [couponExists, setCouponExists] = useState(false);

  const mediaMatch = window.matchMedia("(min-width: 600px)");
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => {
      setMatches(e.matches);
    };
    mediaMatch.addEventListener("change", handler);
    return () => mediaMatch.removeEventListener("change", handler);
  });


  const [phoneNumberVerify, setPhoneNumberVerify] = useState(false);
  const [phoneNum, setPhoneNum] = useState();
  useEffect(() => {
    if (sessionStorage.getItem("discount-coupon")) {
      addLoading("PICKCOUPON")
      CouponsService.getCouponProduct(sessionStorage.getItem("discount-coupon"))
        .then((response) => {
          setCouponExists(true);
          setCouponInfo(response.data);
        })
        .catch((error) => {
          console.log("Error on getting coupon information");
        })
        .finally(()=>{
          removeLoading("PICKCOUPON")
        })
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // console.debug("init pickprogram")
    if(!(isLoading("INIT"))){
      addLoading("INIT")
    }
    if(loggedIn){
      if (UserService.isSubscriptionAction()) {
        // user is not initialized fully yet
        AwsApiService.onRegisterConfirm();
        GoogleAnalytics.onRegisterConfirm();
        AccountsService.checkSubscriptionPostAction()
          .then((response) => {
            if ([2].includes(response.data.code)) {//todo check this
              UserService.resolveSubscriptionAction(response.data.token, false);
            } else {
                setVerificationSent(true)
                removeLoading("INIT");
            }
          })
          .catch((error) => {
            if ([44].includes(error.response.data.code)) {
              navigate("/");
            }else if([403].includes(error.response.data.code)){
              if(error.response.data.token){
                const phoneNum = jwt_decode(JSON.parse(sessionStorage.getItem("kc_action")).token).phoneNumber;
                setPhoneNum(phoneNum);
                if(isValidPhoneNumber(phoneNum)){
                  sessionStorage.setItem("cardValidationToken", error.response.data.token)
                  CardValidationService.generateOTP(
                      {
                        phoneNumber: phoneNum,
                      },
                      error.response.data.token
                  )
                      .then((response) => {
                        setPhoneNumberVerify(true);
                        sessionStorage.setItem("phoneVerificationToken", response.data.token);
                      })
                      .catch((error) => {
                        //todo error return from back
                        console.log("Failure: generateOTP")
                      })
                      .finally(()=>{
                          removeLoading("INIT");
                      })
                }else{
                  //should never go here
                }
              }else{
                //todo error return from back
              }
            }
          })
      } else {
          removeLoading("INIT")
      }
    }
  }, [loggedIn]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="pick-program">
        {isLoading() ? (
              <Loader />
        ) : (
          <div className="pick-program-wrapper">
            <Program
              couponInfo={couponInfo}
              couponExists={couponExists}
              matches={matches}
              token={token}
              flow={flow}
            />
          </div>
        )}
      </div>
      <PopUp
          openPopUp={verificationSent}
          setOpenPopUp={setVerificationSent}
          maxWidth="md"
          showCloseButton={false}
          outlined={false}
          error={false}
          closeWhenClickedOutside={false}
      >
        <GenericError
            setClose={setVerificationSent}
            success={true}
            mainText={"Επιβεβαίωση email"}
            buttonText={"Συνέχεια"}
            showIcon={false}

            // executeFunction={() => navigate("/Account")}
        >
          <div className={"verify-email-popup-secondary-wrapper"}>
            <span className={"verify-email-popup-secondary-first"} >Σύντομα θα λάβεις ένα μήνυμα στο email σου. Παρακαλούμε ακολούθησε τις οδηγίες που αναγράφονται σε αυτό, προκειμένου να επιβεβαιώσεις την διεύθυνση email που μας έχεις δηλώσει.</span>
            <span className={"verify-email-popup-secondary-sub"} >Παρακαλούμε ελέγξτε και τον φάκελο με τα ανεπιθύμητα.</span>
          </div>
        </GenericError>
      </PopUp>
      <PopUp
          openPopUp={phoneNumberVerify}
          setOpenPopUp={(state)=>{
            if(state === false){
              setVerificationSent(true)
            }
            setPhoneNumberVerify(state)
          }}
          maxWidth="md"
          showCloseButton={true}
          outlined={false}
          error={false}
          closeWhenClickedOutside={false}
      >
        <PinVerification
            showBackButton={false}
            isModal={true}
            mobile={phoneNum}
            successAction={(token)=>{
              // addLoading("ACCOUNTPINVERIFICATIONSUCCESS")
              AccountsService.patchUserForAction({phoneNumber: phoneNum, flowToken: token  })
                  .then((response) => {
                    setPhoneNumberVerify(false);
                    setVerificationSent(true)
                    // navigate(0);
                  })
                  .catch((err) => {
                      setPhoneNumberVerify(false);
                      setVerificationSent(true)
                  })
                  .finally(()=>{
                    // removeLoading("ACCOUNTPINVERIFICATIONSUCCESS")
                  })
            }}
        />
      </PopUp>
    </div>
  );
}

export default PickProgram;
