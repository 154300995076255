import React, {useEffect, useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import UserService from "../../services/UserService";
import Loader from "../Loader/Loader";
import {useAuth} from "../../context/AuthContext";
import {useAccount} from "../../context/AccountContext";

const ProtectedRoute = () => {
    let account = useAccount();
    const {loggedIn} = useAuth();
    const [loading, setLoading] = useState(true);

    const location = useLocation();

    useEffect(() => {
        let timeoutId;
        let timeoutIdRetry;

        const triggerTimeout = () => {
            if (loggedIn !== undefined && !loggedIn) {
                // console.debug("Retrying authorization")
                try{
                    UserService.doLogin(window.location.href,account.language);
                }catch (e){
                    // console.debug("Error slow connection")
                }
                timeoutId = setTimeout(triggerTimeout, 10000);
            }else{
                setLoading(false);
            }
        };

        const triggerRetry = ()=>{
            if (loggedIn !== undefined && !loggedIn) {
                timeoutId = setTimeout(triggerTimeout, 0);
            }else if(loggedIn){
                setLoading(false);
            }else{
                timeoutIdRetry = setTimeout(triggerRetry, 500);
            }
        }

        triggerRetry()

        return () => {
            clearTimeout(timeoutId);
            clearTimeout(timeoutIdRetry);
        };
    }, [loggedIn]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        loading ? <Loader /> :
            <DelayedComponent key={location.pathname} Component={Outlet} />
    );
};

export default ProtectedRoute;


const DelayedComponent = ({Component}) => {
    const [showComponent, setShowComponent] = useState(false);

    const { loggedIn, authUpdateUser } = useAuth();

    useEffect(() => {
        if(loggedIn && !UserService.isSubscriptionAction()){//we dont want to update the user data if the
            // console.debug("starting updating token")
            // user is not loggedin or has pending subscription action
            authUpdateUser()
                .then(response =>{
                    // console.debug("updated token")
                    setShowComponent(true);
                })
                .catch(e =>{
                    // console.debug("Error updating token.")
                })
        }else{
            setShowComponent(true)
        }
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    return showComponent ? <Component /> : <Loader />;
}