const basePath = "/api/v1";
const basePathNoAuth = "/api";

// const baseUrl = `http://host.docker.internal${basePath}`;
const baseUrl = process.env.REACT_APP_BACKEND_URL
  ? `${process.env.REACT_APP_BACKEND_URL}${basePath}`
  : window.location.origin + basePath;
const baseUrlNoAuth = process.env.REACT_APP_BACKEND_URL
  ? `${process.env.REACT_APP_BACKEND_URL}${basePathNoAuth}`
  : window.location.origin + basePathNoAuth;

const getOptions = (baseUrl, endPoint = "", parameters = {}) => {
  return {
    url: `${baseUrl}${endPoint}?` + new URLSearchParams(parameters).toString(),
    method: "GET",
    headers: {
      Authorization: `Bearer ${
        JSON.parse(sessionStorage.getItem("kc_tok"))
          ? JSON.parse(sessionStorage.getItem("kc_tok")).token
          : ""
      }`,
    },
  };
};

const getOptionsForAction = (baseUrl, endPoint = "", parameters = {}) => {
  return {
    url: `${baseUrl}${endPoint}?` + new URLSearchParams(parameters).toString(),
    method: "GET",
    headers: {
      Authorization: `Bearer ${
        JSON.parse(sessionStorage.getItem("kc_action"))
          ? JSON.parse(sessionStorage.getItem("kc_action")).token
          : ""
      }`,
    },
  };
};

const patchOptionsForAction = (baseUrl, endPoint = "", data = {}) => {
  return {
    url: `${baseUrl}${endPoint}`,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("kc_action"))
              ? JSON.parse(sessionStorage.getItem("kc_action")).token
              : ""
      }`,
    },
    data: data,
  };
};

const getOptionsNoAuth = (baseUrl, endPoint = "", parameters = {}) => {
  return {
    url: `${baseUrl}${endPoint}?` + new URLSearchParams(parameters).toString(),
    method: "GET",
    headers: {},
  };
};

const putOptions = (baseUrl, endPoint = "", data = {}) => {
  return {
    url: `${baseUrl}${endPoint}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${
        JSON.parse(sessionStorage.getItem("kc_tok"))
          ? JSON.parse(sessionStorage.getItem("kc_tok")).token
          : ""
      }`,
    },
    data: data,
  };
};

const postOptions = (baseUrl, endPoint = "", data = {}) => {
  return {
    url: `${baseUrl}${endPoint}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${
        JSON.parse(sessionStorage.getItem("kc_tok"))
          ? JSON.parse(sessionStorage.getItem("kc_tok")).token
          : JSON.parse(sessionStorage.getItem("kc_action")).token
      }`,
    },
    data: data,
  };
};

const deleteOptions = (baseUrl, endPoint = "", data = {}) => {
  return {
    url: `${baseUrl}${endPoint}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${
        JSON.parse(sessionStorage.getItem("kc_tok"))
          ? JSON.parse(sessionStorage.getItem("kc_tok")).token
          : ""
      }`,
    },
    data: data,
  };
};

const patchOptions = (baseUrl, endPoint = "", data = {}) => {
  return {
    url: `${baseUrl}${endPoint}`,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${
        JSON.parse(sessionStorage.getItem("kc_tok"))
          ? JSON.parse(sessionStorage.getItem("kc_tok")).token
          : ""
      }`,
    },
    data: data,
  };
};

const otpPostOptions = (baseUrl, endPoint = "", data = {},token) => {
  return {
    url: `${baseUrl}${endPoint}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${
        JSON.parse(sessionStorage.getItem("kc_tok"))
          ? JSON.parse(sessionStorage.getItem("kc_tok")).token
          : JSON.parse(sessionStorage.getItem("kc_action")).token
      }`,
      "Accept-Ticket":`Flow ${token}`
    },
    data: data,
  };
};

export {
  baseUrl,
  baseUrlNoAuth,
  getOptions,
  putOptions,
  postOptions,
  getOptionsNoAuth,
  deleteOptions,
  patchOptions,
  getOptionsForAction,
  patchOptionsForAction,
  otpPostOptions
};
