import {createContext, useContext, useState} from 'react';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
    const [loading, setIsLoading] = useState(true);
    const startLoading = () => {
        setIsLoading(true);
    };

    const stopLoading = () => {
        setIsLoading(false);
    };

    const [loadingQueue, setLoadingQueue] = useState([]);

    const addLoading = (key) => {
        setLoadingQueue((prevQueue) => [...prevQueue, key]);
    };

    const removeLoading = (key) => {
        setLoadingQueue((prevQueue) => prevQueue.filter((item) => item !== key));
    };

    const isLoading = (key) => {
        if(!!key){
            return loadingQueue.includes(key);
        }else{
            return loadingQueue.length !== 0;
        }
    };

    // useEffect(()=>{
    //     console.debug(loadingQueue)
    // }, [loadingQueue])

    return (
        <LoadingContext.Provider value={{
            loading,
            startLoading,
            stopLoading,
            addLoading,
            removeLoading,
            isLoading
            // appLoading,
        }}>
            {children}
        </LoadingContext.Provider>
    )
}

export const useLoading = () => useContext(LoadingContext);