import React, { useEffect, useState } from "react";
import "./Contest.css";
import ProductsService from "../../services/ProductsService";
import CmsHelper, { LandingPageHelper } from "../Utilities/CmsHelpers";
import {useCms} from "../../context/CmsContext";
import {useAuth} from "../../context/AuthContext";
import {useAccount} from "../../context/AccountContext";

function Contest() {
  const [productName, setProductName] = useState("");

  const account = useAccount();

  const {authData} = useAuth();

  useEffect(() => {
    if (!!authData.subscriptionId) {
      ProductsService.getProductReferenceStandalone({
        productId: authData.productId,
      })
        .then(({ data }) => {
          setProductName(data.displayName);
        })
        .catch((error) => {
          // console.log(error.response);
        });
    }
  }, [authData]);

  const {landingData} = useCms();

  const headerData = LandingPageHelper.getHeaderSection(landingData,
    ["", "Lite"].includes(productName)
      ? "free"
      : "paid");

  return (
      CmsHelper.isJsonEmpty(headerData) ? (
        <></>
      ): (
        <div className={`ant1-contest ${!account.inHomepage || CmsHelper.isJsonEmpty(headerData) ? "hidden" : ""}`}>
          {
            headerData.image.data
              ? <img src={CmsHelper.getImagePath(headerData.image)} alt="ant1+ plus sign" />
              : ""
          }
          <a href={headerData?.CallToAction} target={"_self"}>{headerData.name}</a>
        </div>
      )
  );
}

export default Contest;
