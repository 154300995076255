import React, { useEffect, useState } from "react";
import "./CarouselDot.css";

function CarouselDot({ slides }) {
  const [active, setActive] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = (id) => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      if (id === slides.length-1) {
        setActive(slides[0].id);
      } else {
        setActive(id + 1);
      }
    }else if(isRightSwipe){
      if (id === 0) {
        setActive(slides[slides.length-1].id);
      } else {
        setActive(id - 1);
      }
    }
  };

  const changeActive = (index) => {
    setActive(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (slides.length > 0) {
        let id = active + 1;
        if (id >= slides.length) id = 0;

        setActive(slides[id].id);
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [active, slides]);

  return (
    <div className="carousel-dot">
      {slides && (
        <>
          <div className="slides-container">
            {slides.map((slide, index) => (
              <React.Fragment key={index}>
                <div
                  className={`slide  ${slide.id === active ? "fade" : "hide"}`}
                  onTouchStart={onTouchStart}
                  onTouchMove={onTouchMove}
                  onTouchEnd={() => {
                    onTouchEnd(slide.id);
                  }}
                >
                  {slide.img}
                </div>
              </React.Fragment>
            ))}
          </div>
          <div className="dots">
            {slides.length > 1 && slides.map((slide, index) => (
              <span
                className={`dot ${slide.id === active ? "active" : ""}`}
                onClick={() => changeActive(slide.id)}
                key={index}
              ></span>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default CarouselDot;
