import {createContext, useContext, useEffect, useState} from 'react';
import Loader from "../components/Loader/Loader";
import CmsService from "../services/CmsService";
import {ScreenHelper} from "../components/Utilities/ScreenHelper";

const CmsContext = createContext();

export const CmsProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);

    const [landingData, setLandingData] = useState({})
    const [pickProgramData, setPickProgramData] = useState([])
    const [questionnaireData, setQuestionnaireData] = useState([])
    const [productBannersData, setProductBannersData] = useState({})
    // const [appLoading   , setAppLoading] = useState(false);
    const [renderingType, setRenderingType] = useState(ScreenHelper.initRenderingType());
    const [landingRenderingType, setLandingRenderingType] = useState(ScreenHelper.initLandingRenderingType());

    useEffect(() => {
        ScreenHelper.getRenderingType(setRenderingType);
    }, []);

    useEffect(() => {
        ScreenHelper.getLandingRenderingType(setLandingRenderingType);
    }, []);

    useEffect(()=>{
        let timeoutId;
        setIsLoading(true)
        const fetchCms =  async ()=>{
            try {
                setLandingData(await CmsService.getLandingPageContent())
                setPickProgramData(await CmsService.getPickProgramPageContent())
                setProductBannersData(await CmsService.getProgramBanners())
                setQuestionnaireData(await CmsService.getQuestionnaires())
                setIsLoading(false);
            }catch (e){
                console.log("error loading cms context")
                timeoutId = setTimeout(fetchCms, 10000);
            }
        }
        timeoutId = setTimeout(fetchCms, 0);

        return () => {
            clearTimeout(timeoutId);
        };
    },[]) //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <CmsContext.Provider value={{
            renderingType,
            landingRenderingType,
            landingData,
            pickProgramData,
            questionnaireData,
            productBannersData
        }}>
            {isLoading ?
                <Loader />
                :
                children
            }
        </CmsContext.Provider>
    )
}

export const useCms = () => useContext(CmsContext);