import React, { useEffect, useRef } from "react";
import "./PopUp.css";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


export default function PopUp({
  title,
  children,
  openPopUp,
  setOpenPopUp,
  showCloseButton = true,
  setModalResult = null,
  maxWidth = "md",
  fullWidth = true,
  outlined = false,
  error = false,
  condensed = false,
  closeWhenClickedOutside = true,
}) {
  const titleRef = useRef(null);
  const contentRef = useRef(null);
  useOutsideAlerter(titleRef, contentRef, setOpenPopUp);

  function useOutsideAlerter(ref1, ref2, setOpenPopUp) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
            ref1.current &&
            !ref1.current.contains(event.target) &&
            ref2.current &&
            !ref2.current.contains(event.target)
        ) {
          if(closeWhenClickedOutside){
            setOpenPopUp(false);
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref1, ref2]); //eslint-disable-line react-hooks/exhaustive-deps
  }

  return (
    <Dialog
      open={openPopUp}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      className={`pop-up ${outlined ? "outlined" : ""} ${
        error ? "error" : ""
      } ${condensed ? "condensed" : ""}`}
    >
      <DialogTitle ref={titleRef}>
        {title}
        {showCloseButton === true ? (
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => {
              setOpenPopUp(false);
              if (setModalResult !== null) setModalResult("failure");
            }}
          >
            <CloseIcon fontSize="large" />
          </button>
        ) : null}
      </DialogTitle>
      <DialogContent ref={contentRef}> {children} </DialogContent>
    </Dialog>
  );
}
