import { Button, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import "./PinVerification.css";
import PinInput from "react-pin-input";
import { Alert } from "@mui/material";
import Countdown from "react-countdown";
import { useLocation, useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";
import CardValidationService from "../../services/CardValidationService";
import AccountsService from "../../services/AccountsService";
import PopUp from "../Utilities/PopUp";
import GenericError from "../Utilities/GenericError";
import {useAuth} from "../../context/AuthContext";
import {useAccount} from "../../context/AccountContext";

const style = {
  pinInput: {
    padding: "10px",
    display: "flex",
    width: "100%",
  },
  inputStyle: {
    color: "#FFFFFF",
    border: "1px solid #FFFFFF",
    backgroundColor: "#000000",
    fontSize: "40px",
    fontWeight: "700",
    width: "56px",
    height: "66px",
  },
  error: {
    border: "1px solid #C64E4E",
    color: "#C64E4E",
    backgroundColor: "#000000",
    fontSize: "40px",
    fontWeight: "700",
    width: "56px",
    height: "66px",
  },
};

function PinVerification({showBackButton= true, mobile= null, isModal= false, successAction}) {
  let navigate = useNavigate();

  //phone number comes in different ways depending if we navigate to thw page or it is a modal
  const { state } = useLocation();
  var phone = mobile;
  if (!phone && !!state.phoneNumber) {
    const { phoneNumber } = state;
    phone = phoneNumber;
  }


  const account = useAccount();
  const { setCheckLoggedIn } = useAuth();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [pin, setPin] = useState(null);
  const [error, setError] = useState(false);
  const [sendAgain, setSendAgain] = useState(false);
  const [success, setSuccess] = useState(false);
  const [couponFail, setCouponFail] = useState(false);
  const [disabledVerify, setDisabledVerify] = useState(false);
  const [actionError, setActionError] = useState(false);
  const [promoRedirectURL, setPromoRedirectURL] = useState()

  useEffect(()=>{
    if(sessionStorage.getItem("promoRedirectURL")){
      setPromoRedirectURL(sessionStorage.getItem("promoRedirectURL"))
      sessionStorage.removeItem("promoRedirectURL")
    }
  },[])
  const onPinComplete = (value) => {
    setPin(value);
  };

  const closeFailActionPopUp = () => {
    setActionError(false);
  };

  const onSendAgain = () => {
    setSendAgain(true);

    CardValidationService.generateOTP(
      {
        phoneNumber: phone,
      },
      sessionStorage.getItem("cardValidationToken")
    )
      .then((response) => {
        sessionStorage.setItem("phoneVerificationToken", response.data.token);
      })
      .catch((error) => {
        // console.log("Error");
      });
  };

  const onCountdownComplete = () => {
    setSendAgain(false);
  };

  const goBack = (e) => {
    e.preventDefault();
    setCheckLoggedIn(false);
    navigate("/PhoneVerification");
  };

  const onVerifyPin = () => {
    var flow = sessionStorage.getItem("cardValidationFlow");
    let isUpgrade = !!flow && flow === "upgrade";
    setDisabledVerify(true);
    setLoading(true);

    CardValidationService.verifyOTP({pinNumber: `${pin}`}, sessionStorage.getItem("phoneVerificationToken"))
        .then((response) => {
          setError(false);
          sessionStorage.removeItem("phoneVerificationToken")

          if(isModal && !!successAction){
            sessionStorage.removeItem("cardValidationToken")
            successAction(response.data.token);
          }else if (isUpgrade) {
            return onUpgrade(response.data.token);
          }else {
            return onSubscribe(response.data.token);
          }
        })
        .catch((error) => {
          setError(true);
          setDisabledVerify(false);
          setLoading(false);
        });
  };

  const onUpgrade = (flowToken) => {
    const request = JSON.parse(sessionStorage.getItem("subscriptionRequest"));
    request.paymentProfile = {
      flowToken: flowToken,
    };
    return AccountsService.upgradeSubscription(request)
      .then((res) => {
        const newProduct = res.data.subscription.product
        account.setupAnalyticsProductConvertion({
          pricePoint: newProduct.product_price_point_handle,
          productName: newProduct.name,
          pricePointId: newProduct.product_price_point_id,
        })

        if (sessionStorage.getItem("discount-coupon")) {
          setSuccess(true);
          setCouponFail(false);
          sessionStorage.removeItem("discount-coupon");
        } else {
          if(promoRedirectURL){
            sessionStorage.setItem("congratsRedirectURL", promoRedirectURL)
          }
          navigate("/CongratsPage");
        }

        sessionStorage.removeItem("cardValidationFlow");
        sessionStorage.removeItem("cardValidationToken");
        sessionStorage.removeItem("subscriptionRequest");
      })
      .catch((error) => {
        if (
          sessionStorage.getItem("discount-coupon") &&
          [4, 101, 421, 423, 424].includes(error.response.data.code)
        ) {
          setCouponFail(true);
          sessionStorage.removeItem("discount-coupon");
        } else {
          setSuccess(false);
          setCouponFail(false);
          setActionError(true);
        }
      });
  };

  const onSubscribe = (flowToken) => {
    if(sessionStorage.getItem("subscriptionRequest")){
      const request = JSON.parse(sessionStorage.getItem("subscriptionRequest"));
      request.paymentProfile = {
        flowToken: flowToken,
      };

      return AccountsService.subscribe(request)
        .then((res) => {
          const newProduct = res.data.subscription.product
          account.setupAnalyticsProductConvertion({
            pricePoint: newProduct.product_price_point_handle,
            productName: newProduct.name,
            pricePointId: newProduct.product_price_point_id,
          })

          sessionStorage.setItem("kc_resolve_token", res.data.token);

          if (UserService.isSubscriptionAction()) {
            if (sessionStorage.getItem("discount-coupon")) {
              setSuccess(true);
              setCouponFail(false);
            } else {
              if(promoRedirectURL){
                sessionStorage.setItem("congratsRedirectURL", promoRedirectURL)
              }
              navigate("/CongratsPage");
            }
          } else {
            if(promoRedirectURL){
              sessionStorage.setItem("congratsRedirectURL", promoRedirectURL)
            }
            navigate("/CongratsPage");
          }
          sessionStorage.removeItem("discount-coupon");
          sessionStorage.removeItem("cardValidationToken");
          sessionStorage.removeItem("subscriptionRequest");
        })
        .catch((error) => {
          if (
            sessionStorage.getItem("discount-coupon") &&
            [4, 101, 421, 423, 424].includes(error.response.data.code)
          ) {
            setCouponFail(true);
            sessionStorage.removeItem("discount-coupon");
          } else {
            setActionError(true);
            setSuccess(false);
          }
        });
    }
  };

  const onAfterSuccessfulCouponUse = () => {
    if(promoRedirectURL){
      sessionStorage.setItem("congratsRedirectURL", promoRedirectURL)
    }
    sessionStorage.setItem("coupon-success", true);
    navigate("/CongratsPage");
  };

  return (
    <>
      {loading ? (
        <Loader isModal={isModal} />
      ) : (
        <div className="pin-verification-wrapper">
          <Grid container item xs={12} className="desktop-center">
            <p className="enter-pin-text">{t("pinVerText")}</p>
          </Grid>

          <Grid container item xs={12}>
            <div className="pin">
              <PinInput
                length={4}
                initialValue=""
                secret={false}
                onChange={(value, index) => {
                  setError(false);
                }}
                type="numeric"
                inputMode="number"
                style={style.pinInput}
                inputStyle={error ? style.error : style.inputStyle}
                inputFocusStyle={{
                  border: "1px solid rgba(0, 135, 255, 0.64)",
                }}
                onComplete={(value, index) => {
                  onPinComplete(value);
                }}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                id="pin-input"
              />
              {error && (
                <Alert
                  severity="error"
                  id="pin-ver-alert"
                  sx={{
                    backgroundColor: "transparent",
                    color: "#C64E4E",
                    padding: "0",
                  }}
                  icon={false}
                >
                  {t("pinVerAlert")}
                </Alert>
              )}
            </div>
          </Grid>

          <Grid
            container
            item
            xs={12}
            justifyContent="flex-end"
            className="desktop-center"
          >
            <div className="buttons-wrapper">
              <Button
                variant="text"
                className="grey"
                onClick={goBack}
                id="goBackButton"
              >
                {t("goBackButton")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                id="verification"
                disabled={disabledVerify}
                onClick={onVerifyPin}
              >
                {t("pinVerButton")}
              </Button>
            </div>
          </Grid>

          <Grid container item xs={12} justifyContent="center">
            {!sendAgain ? (
              <div className="re-send">
                <span>{t("resendText")}</span>
                <Button variant="text" color="blue" className="blue" onClick={onSendAgain}>
                  {t("resendButton")}
                </Button>
              </div>
            ) : (
              <Countdown
                date={Date.now() + 15000}
                renderer={(props) => (
                  <div className="countdown">
                    <span style={{ color: "white", marginRight: "10px" }}>
                      {t("resendCountText")}
                    </span>
                    {props.minutes.toString().length === 1
                      ? `0${props.minutes}`
                      : props.minutes}
                    :
                    {props.seconds.toString().length === 1
                      ? `0${props.seconds}`
                      : props.seconds}
                  </div>
                )}
                onComplete={onCountdownComplete}
              />
            )}
          </Grid>
        </div>
      )}
      <PopUp
        openPopUp={success}
        setOpenPopUp={setSuccess}
        maxWidth="md"
        showCloseButton={false}
        outlined={false}
        error={false}
      >
        <GenericError
          setClose={setSuccess}
          success={true}
          mainText={"Επιτυχής εξαργύρωση κωδικού!"}
          secondaryText={"Απόλαυσε δωρεάν όλο το περιεχόμενο του ΑΝΤ1+."}
          buttonText={"Συνέχεια"}
          showIcon={false}
          executeFunction={() => onAfterSuccessfulCouponUse()}
        />
      </PopUp>
      <PopUp
        openPopUp={couponFail}
        setOpenPopUp={setCouponFail}
        maxWidth="md"
        showCloseButton={false}
        outlined={false}
        error={true}
      >
        <GenericError
          setClose={setCouponFail}
          success={true}
          mainText={"Αποτυχία εξαργύρωσης!"}
          secondaryText={"Ο κωδικός δεν είναι έγκυρος"}
          buttonText={"Συνέχεια"}
          showIcon={false}
          executeFunction={() => navigate("/Account")}
        />
      </PopUp>
      <PopUp
        openPopUp={actionError}
        setOpenPopUp={setActionError}
        maxWidth="md"
        showCloseButton={false}
        outlined={false}
        error={true}
      >
        <GenericError
          setClose={setActionError}
          mainText={"Σφάλμα κατά την πληρωμή!"}
          secondaryText={"Παρακαλώ δοκιμάστε αργότερα"}
          executeFunction={closeFailActionPopUp}
        />
      </PopUp>
    </>
  );
}

export default PinVerification;
