import {createContext, useContext, useState} from "react";
import {useAuth} from "./AuthContext";
import jwt_decode from "jwt-decode";
const AccountContext = createContext();
export const AccountProvider = ({ children }) => {
    const [inHomepage, setInHomepage] = useState(false);
    const [language, setLanguage] = useState("gr");
    const [userData, setUserData] = useState({
        id: -1,
        username: "",
        email: "",
    });
    const [chargifyAccess, setChargifyAccess] = useState(false);
    const [productId, setProductId] = useState();
    const [pricePoint, setPricePoint] = useState();
    const [changePayment, setChangePayment] = useState(false);
    const [chosenProgramName, setChosenProgramName] = useState("");
    const [skipFilters, setSkipFilters] = useState(false);
    const [migrateFromCancellation, setMigrateFromCancellation] = useState(false);

    const clearSessionCoupon = () => {
        if (sessionStorage.getItem("discount-coupon")) {
            sessionStorage.removeItem("discount-coupon");
        }
    };


    const {authData} = useAuth();
    const [analyticsProductConversion, setAnalyticsProductConvertion] = useState(null)
    const setupAnalyticsProductConvertion = (newProductData) =>{
        if(authData.email !== null){
            setAnalyticsProductConvertion({
                email: authData.email,
                path: "upgrade",
                initial_package_name: authData?.productName,
                initial_package_pricepoint: authData?.pricePointHandle,
                initial_pricepoint_id: authData?.pricePointId,

                new_package_name: newProductData?.productName,
                new_package_pricepoint: newProductData?.pricePoint,
                new_pricepoint_id: newProductData?.pricePointId
            })
        }else{
            setAnalyticsProductConvertion({
                email: `${jwt_decode(JSON.parse(sessionStorage.getItem("kc_action")).token).email}`,
                path: "new",

                new_package_name: newProductData?.productName,
                new_package_pricepoint: newProductData?.pricePoint,
                new_pricepoint_id: newProductData?.pricePointId
            })
        }
    }

    return (
        <AccountContext.Provider
            value={{
                inHomepage, setInHomepage,
                language, setLanguage,
                userData, setUserData,
                chargifyAccess, setChargifyAccess,
                productId, setProductId,
                changePayment, setChangePayment,
                chosenProgramName, setChosenProgramName,
                skipFilters, setSkipFilters,
                pricePoint, setPricePoint,
                migrateFromCancellation, setMigrateFromCancellation,
                clearSessionCoupon,
                analyticsProductConversion, setupAnalyticsProductConvertion
            }}>
            {children}
        </AccountContext.Provider>
    )
}

export const useAccount = () => useContext(AccountContext);
