import React from "react";
import "./Devices.css";
import CmsHelper, { LandingPageHelper } from "../../Utilities/CmsHelpers";
import {useCms} from "../../../context/CmsContext";

function Devices() {
  const {landingData} = useCms();
  const devices = LandingPageHelper.getDevicesSection(landingData);

  return (<>
        {!!devices.title &&
          <div id="devices">
            <div id="devices-wrapper">
              <div className="text-part">
                <h2>{devices.title}</h2>
                <p>{devices.details}</p>
              </div>
              <div>
                <img src={CmsHelper.getImagePath(devices.logo)} alt="ant1 available devices"/>
              </div>
            </div>
          </div>
        }</>);
}

export default Devices;
