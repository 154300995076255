import React from "react";
import "../GlobalStyles.css";
import "./DownloadApps.css";
import { isMobile, isAndroid, isIOS, isDesktop } from "react-device-detect";

function DownloadApps({
  grey = false,
  text,
  desktopHidden = true,
  account = false,
}) {
  return (
    <div
      className={`download-apps ${grey ? "grey" : ""} ${
        !isMobile && desktopHidden ? "hidden" : ""
      } ${account ? "account" : ""}`}
    >
      <div className="title">{text}</div>
      <div className="logos">
        {isIOS && (
          <a
            href="https://apps.apple.com/gr/app/ant1/id1554790591"
            target="_blank"
            rel="noreferrer"
          >
            <div className="download-mobile-link">
              <img src="images/appStore.png" alt="app store logo" />
            </div>
          </a>
        )}
        {isAndroid && (
          <a
            href="https://play.google.com/store/apps/details?id=gr.antenna.ant1"
            target="_blank"
            rel="noreferrer"
          >
            <div className="download-mobile-link">
              <img src="images/googlePlay.png" alt="google play logo" />
            </div>
          </a>
        )}
        {isDesktop && (
          <>
            <a
              href="https://apps.apple.com/gr/app/ant1/id1554790591"
              target="_blank"
              rel="noreferrer"
            >
              <div className="download-mobile-link">
                <img src="images/appStore.png" alt="app store logo" />
              </div>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=gr.antenna.ant1"
              target="_blank"
              rel="noreferrer"
            >
              <div className="download-mobile-link">
                <img src="images/googlePlay.png" alt="google play logo" />
              </div>
            </a>
          </>
        )}
      </div>
    </div>
  );
}

export default DownloadApps;
