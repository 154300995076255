import React, { useEffect, useState } from "react";
import "../GlobalStyles.css";
import "./CashCoupon.css";
import { Button, TextField } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";
import CouponsService from "../../services/CouponsService";
import PopUp from "../Utilities/PopUp";
import GenericError from "../Utilities/GenericError";
import {useAuth} from "../../context/AuthContext";
import {useLoading} from "../../context/LoadingContext";

function CashCoupon() {
  let navigate = useNavigate();

  const { setCheckLoggedIn  } = useAuth();
  const { isLoading, removeLoading, addLoading} = useLoading();
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponInUse, setCouponInUse] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    setCouponCode(e.target.value);
  };

  const onCashCoupon = (e) => {
    e.preventDefault();
    addLoading("CACHCOUPON")
    CouponsService.checkCouponValidity(couponCode)
      .then((response) => {
        setError(false);
        sessionStorage.setItem("discount-coupon", couponCode);
        navigate("/PickProgram");
      })
      .catch((error) => {
        setError(true);
      })
      .finally(()=>{
        removeLoading("CACHCOUPON")
      });
  };

  const onGoBack = () => {
    setCheckLoggedIn(false);
    navigate("/Account");
  };

  useEffect(()=>{
    removeLoading("INIT")
  }, [])//eslint-disable-line react-hooks/exhaustive-deps

  const onAfterSuccessfulCouponUse = () => {
    sessionStorage.setItem("coupon-success", true);
    navigate("/CongratsPage");
  };

  return (
    <>
      {isLoading() ? (
        <Loader />
      ) : (
        <div className="cash-coupon-wrapper">
          <div className="coupon-top-section">
            <div>
              <Button
                variant="text"
                className="blue"
                id="goBackButton"
                onClick={onGoBack}
              >
                <ArrowBackIcon />
                {t("backToAccountPage")}
              </Button>
            </div>
            <h1>{t("cashCouponTitle")}</h1>
            <p>{t("cashCouponInfo")}</p>

            <form autoComplete="off" onSubmit={onCashCoupon}>
              <div className="field">
                <label>{t("password")}</label>
                <TextField
                  error={error}
                  variant="outlined"
                  fullWidth
                  // type="number"
                  name="coupon-code"
                  onChange={handleChange}
                  className={`default ${error ? "error" : ""}`}
                  value={couponCode}
                />
                {error && (
                  <Alert
                    id="invalid-coupon"
                    severity="error"
                    sx={{
                      backgroundColor: "transparent",
                      color: "#C64E4E",
                      padding: "0",
                    }}
                    icon={false}
                  >
                    {t("couponCodeInvalid")}
                  </Alert>
                )}
              </div>

              <div>
                <Button
                  variant="contained"
                  color="primary"
                  id="cashButton"
                  onClick={onCashCoupon}
                >
                  {t("cashCouponButton")}
                </Button>
              </div>
            </form>
          </div>

          <PopUp
            openPopUp={couponInUse}
            setOpenPopUp={setCouponInUse}
            maxWidth="md"
            showCloseButton={false}
            outlined={false}
            error={true}
          >
            <GenericError
              setClose={setCouponInUse}
              success={true}
              mainText={"Αποτυχία εξαργύρωσης!"}
              secondaryText={"Ο κωδικός δεν είναι έγκυρος"}
              buttonText={"Συνέχεια "}
              showIcon={false}
            />
          </PopUp>
          <PopUp
            openPopUp={success}
            setOpenPopUp={setSuccess}
            maxWidth="md"
            showCloseButton={false}
            outlined={false}
            error={false}
          >
            <GenericError
              success={true}
              mainText={"Επιτυχής εξαργύρωση κωδικού!"}
              secondaryText={"Απόλαυσε δωρεάν όλο το περιεχόμενο του ΑΝΤ1+."}
              buttonText={"Συνέχεια"}
              showIcon={false}
              executeFunction={() => onAfterSuccessfulCouponUse()}
            />
          </PopUp>
        </div>
      )}
    </>
  );
}

export default CashCoupon;
