import "./CheckBox.css"
function CheckBox({children, text, action, checked, disabled}){
    return(
        <label className={"checkbox-wrapper"}>
            <input type={"checkbox"} disabled={disabled} checked={checked} onChange={(e)=>{action(e)}}/>
            <span className={`checkbox ${checked ? "checkbox--active":""}`} aria-hidden={true}/>
            <span className={"checkbox-label"}>{!!text? text : children}</span>
        </label>
)
}

export default CheckBox;