import axios from "axios";
import { osName, osVersion } from "react-device-detect";
import UserService from "./UserService";
import jwt_decode from "jwt-decode";

const api =
  "https://e7qj2vpzag.execute-api.eu-north-1.amazonaws.com/AntennaTVStage/logs";

const data = {
  eventTime: new Date().toISOString(),
  dType: navigator.platform,
  event: "appNavigate",
  appCode: "ant1",
  dm: navigator.userAgent,
  os: osName + " " + osVersion,
  tab: "Tab:Live",
  serviceType: "default",
  ct: "Other",
  av: "1.0.38",
  cn: "NOT CELLULAR",
  dID: "2cdc85e3e66a3a3973e45e8fd9fc68ff",
  aSession: "12c7d0c698e604ca72180e303674fbb6",
};

function onLogin() {
  UserService.getUser();
  const request = {
    ...data,
    sPID: "embed:/Login",
    lPID: "embed:/Login",
    account: "00",
  };

  axios
    .post(api, request)
    .then((response) => {
      //   console.log("SUCCESS");
      //   console.log(response);
    })
    .catch((error) => {
      //   console.log("FAIL");
      //   console.log(error.response);
    });
}

function onRegister() {
  const request = {
    ...data,
    sPID: "embed:/Register",
    lPID: "embed:/Register",
    account: "00",
  };

  axios
    .post(api, request)
    .then((response) => {
      //   console.log("SUCCESS");
      //   console.log(response);
    })
    .catch((error) => {
      //   console.log("FAIL");
      //   console.log(error.response);
    });
}

function onRegisterConfirm() {
  UserService.getUser();
  const request = {
    ...data,
    sPID: "embed:/PickProgram",
    lPID: "embed:/PickProgram",
    account: jwt_decode(JSON.parse(sessionStorage.getItem("kc_action")).token)
      .sub,
  };

  axios
    .post(api, request)
    .then((response) => {
      //   console.log("SUCCESS");
      //   console.log(response);
    })
    .catch((error) => {
      //   console.log("FAIL");
      //   console.log(error.response);
    });
}

function onPickProgram() {
  const request = {
    ...data,
    sPID: "embed:/PickProgram",
    lPID: "embed:/PickProgram",
    account: "00",
  };

  axios
    .post(api, request)
    .then((response) => {
      //   console.log("SUCCESS");
      //   console.log(response);
    })
    .catch((error) => {
      //   console.log("FAIL");
      //   console.log(error.response);
    });
}

function onWelcome(productName) {
  const request = {
    ...data,
    sPID: "embed:/signup/" + productName,
    lPID: "embed:/signup/" + productName,
    account: sessionStorage.getItem("kc_resolve_token")
      ? jwt_decode(sessionStorage.getItem("kc_resolve_token")).sub
      : jwt_decode(sessionStorage.getItem("kc_tok")).sub,
  };

  axios
    .post(api, request)
    .then((response) => {
      //   console.log("SUCCESS");
      //   console.log(response);
    })
    .catch((error) => {
      //   console.log("FAIL");
      //   console.log(error.response);
    });
}

const AwsApiService = {
  onLogin,
  onPickProgram,
  onRegister,
  onWelcome,
  onRegisterConfirm,
};

export default AwsApiService;
