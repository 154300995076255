import {createContext, useContext, useEffect, useState} from 'react';
import UserService from "../services/UserService";
import AccountsService from "../services/AccountsService";
import {useLoading} from "./LoadingContext";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [loggedIn, setLoggedIn] = useState();
    const [authorizing, setAuthorizing] = useState(false);
    const [checkLoggedIn, setCheckLoggedIn] = useState(true);

    const [authData, setAuthData] = useState({email: null, productId: null})
    const { addLoading, removeLoading, isLoading } = useLoading();
    const login = (redirect_uri, language) => {
        UserService.doLogin(redirect_uri, language)
    };

    useEffect(() => {
        //init keycloak
        addLoading("AUTH")
        UserService.initKeycloak(
            setLoggedIn,
            setAuthorizing
        )
            .then((authenticated) => {
                UserService.setAuthorizing(false, setAuthorizing);
                if (authenticated) {
                    authUpdateUser();
                } else {
                    if(UserService.isSubscriptionAction()){//case insufficient register
                        setLoggedIn(true);
                        if(window.location.pathname !== "/PickProgram" && window.location.pathname !== "/") {//incase if user has pending subscription action, can only access pickprogram
                            window.location.replace("/PickProgram");
                        }
                    }else{
                        setLoggedIn(false);
                    }
                    UserService.clearUserTokens();
                }
            })
            .catch(function (d) {
                UserService.setAuthorizing(false, setAuthorizing);
                UserService.clearUserTokens();
                setLoggedIn(false);
            })
            .finally(()=>{
                addLoading("INIT")
                removeLoading("AUTH")
            })
        // eslint-disable-next-line
    }, []);

    const authUpdateUser = async () => {
        if(!isLoading("AUTH")){
            addLoading("AUTH")
        }
        try {
            await UserService.updateUserTokens();
            const response = await AccountsService.getAccounts();
            setAuthData({
                email: response.data.email,
                productName: response.data.subscription?.product.name,
                productId: response.data.subscription?.product.id,
                subscriptionId: response.data.subscription?.id,
                subscriptionState: response.data.subscription?.state,
                pricePointHandle: response.data.subscription?.product.product_price_point_handle,
                pricePoint: response.data.subscription?.pricePoint,
                pricePointId: response.data.subscription?.product_price_point_id
            })
            setLoggedIn(true)
        }catch(e){
                setLoggedIn(false);
        }finally {
            addLoading("INIT")
            removeLoading("AUTH")
        }
    }

    return (
        <AuthContext.Provider value={{
            loggedIn,
            setLoggedIn,
            authorizing,
            setAuthorizing,
            checkLoggedIn,
            setCheckLoggedIn,
            authData,
            authUpdateUser,
            login,
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext);