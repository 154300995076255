import React, {useEffect, useRef, useState} from "react";
import "./Program.css";
import "../GlobalStyles.css";
import CheckIcon from "@mui/icons-material/Check";
import {Button} from "@mui/material";
import AccountsService from "../../services/AccountsService";
import {useNavigate} from "react-router-dom";
import ProductsService from "../../services/ProductsService";
import PopUp from "../Utilities/PopUp";
import {useTranslation} from "react-i18next";
import GenericError from "../Utilities/GenericError";
import UserService from "../../services/UserService";
import GoogleAnalytics from "../../analytics/GoogleAnalytics";
import AwsApiService from "../../services/AwsApiService";
import Loader from "../Loader/Loader";
import CmsHelper, {PickProgramPageHelper, ProgramBannersHelper} from "../Utilities/CmsHelpers";
import ProductContainer from "./components/ProductContainer/ProductContainer";
import {useCms} from "../../context/CmsContext";
import {useAuth} from "../../context/AuthContext";
import {useAccount} from "../../context/AccountContext";

function Program({couponInfo, couponExists, matches, token = null, flow = null}) {
  let navigate = useNavigate();
  let account = useAccount();
  const {loggedIn, authData, setCheckLoggedIn} = useAuth()
  const { t } = useTranslation();

  const [packages, setPackages] = useState([]);
  const [chosenProgram, setChosenProgram] = useState({
    productId: -1,
    pricePoint: "",
  });
  const [upgrade, setUpgrade] = useState(false);
  const [error, setError] = useState(false);
  const [sameProductVerification, setSameProductVerification] = useState(false);
  const [genericError, setGenericError] = useState(false);
  const [canUpgrade, setCanUpgrade] = useState(true);
  const [successfulCouponUse, setSuccesfulCouponUse] = useState(false);
  const [failedCouponUse, setFailedCouponUse] = useState(false);
  const [hasFreeProduct, setHasFreeProduct] = useState(false);
  const [loading, setLoading] = useState(false);
  const [preselected, serPreselected] = useState();

  const [cardInUse, setCardInUse] = useState(false);

  const [SaveDesk2error, setSaveDesk2error] = useState(false);

  const [subscription, setSubscription] = useState({
    productId: chosenProgram.productId,
    newProductId: chosenProgram.productId,
  });

  const packagesRef = useRef([]);
  useEffect(() => {
    if (packages.length > 0) {
      packagesRef.current = packagesRef.current.slice(0, packagesRef.length);
    }
  }, [packages]);


  const { renderingType, pickProgramData, productBannersData  } = useCms();


  const productBanners = productBannersData;

  const [update, setUpdate] = useState()

  const [cmsProgramsData, setCmsProgramsData] = useState()

  useEffect(() => {
    if (preselected && packagesRef.current.length > 0) {
      let element = packagesRef.current.find(
        (el) => el.id === preselected.displayName
      );
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }
  }, [preselected]);

  const onUseCoupon = (code) => {
    const couponCodes = { couponCodes: [code] };
    setCheckLoggedIn(true);
    AccountsService.addCoupon(couponCodes)
      .then((response) => {
        //added coupon to svod acount
        setSuccesfulCouponUse(true);
        setFailedCouponUse(false);
      })
      .catch((error) => {
        //failed to add coupon to svod account
        setSuccesfulCouponUse(false);
        setFailedCouponUse(true);
      })
      .finally(() => {
        sessionStorage.removeItem("discount-coupon");
      });
  };

  let promoRedirectURL= undefined
  const [promoRedirectURLState, setPromoRedirectURLState] = useState()

  // let contestSession= undefined
  const [contestState, setContestState] = useState()
  const [contestRedirectURLState, setContestRedirectUrlState] = useState()

  useEffect(() => {
    if(loggedIn){
      setLoading(true);
      if(sessionStorage.getItem("promoRedirectURL")){
        // eslint-disable-next-line
        promoRedirectURL = sessionStorage.getItem("promoRedirectURL")
        setPromoRedirectURLState(sessionStorage.getItem("promoRedirectURL"))
        sessionStorage.removeItem("promoRedirectURL")
      }

      let contestSession;
      let contestRedirectURL;
      if(sessionStorage.getItem("contestSession")){
        // eslint-disable-next-line
        contestSession = sessionStorage.getItem("contestSession")
        setContestState(contestSession)
        setContestRedirectUrlState(sessionStorage.getItem("contestRedirectURL"))
        contestRedirectURL =sessionStorage.getItem("contestRedirectURL");
        sessionStorage.removeItem("contestSession");
        sessionStorage.removeItem("contestRedirectURL");
      }

      const preselectedFromLanding = JSON.parse(
        sessionStorage.getItem("selectedProgramId")
      );

      if(token){
        // case user has savedesk token

        //filter cms program data
        setCmsProgramsData(PickProgramPageHelper.getPrograms(pickProgramData, CmsHelper.UPGRADE_PROGRAM))

        // used to get product references for upgrade
        let activeSubscription = {
          productId: !!authData.productId
              ? authData.productId
              : -1,
          pricePoint: !!authData.pricePoint ? authData.pricePoint : undefined,
        };
        ProductsService.getProducts((token !== null ? {token: token} : {}))
            .then((response) => {
              handleTokenSaveDeskMigration(
                  authData,
                  response,
                  activeSubscription,
                  preselectedFromLanding
              );
            })
            .catch((error) => {
              setSaveDesk2error(true);
              setLoading(false)
              // console.log(error.response)
            });
      } else if (flow && flow === "newsletter") {
        // case user has newsletter flow

        //filter cms program data
        setCmsProgramsData(PickProgramPageHelper.getPrograms(pickProgramData, CmsHelper.UPGRADE_PROGRAM))

        // used to get product references for upgrade
        let activeSubscription = {
          productId: !!authData.productId
              ? authData.productId
              : -1,
          pricePoint: !!authData.pricePoint ? authData.pricePoint : undefined,
        };

        ProductsService.getProductReferenceStandalone({...activeSubscription, flow: "newsletter"})
            .then((response) => {
              handleTokenSaveDeskMigration(
                  authData,
                  response,
                  activeSubscription,
                  preselectedFromLanding
              );
            })
            .catch((error) => {
              setLoading(false)
              // console.log(error.response)
            });
      }else if (!!authData.subscriptionId || (!(!!UserService.isSubscriptionAction()) && !!sessionStorage.getItem("discount-coupon"))) {
        //user already has a subscription or has coupon upon registration
        setUpdate(true);


        // used to get product references for upgrade
        let activeSubscription = {
          productId: !!authData.productId
              ? authData.productId
              : -1,
          pricePoint: !!authData.pricePoint ? authData.pricePoint : undefined,
        };

        if (account.migrateFromCancellation) {
          //unsubscribe flow

          //filter cms program data
          setCmsProgramsData(PickProgramPageHelper.getPrograms(pickProgramData, CmsHelper.CANCELLATION_MIGRATE))

          ProductsService.getCancellationProductReference({
            productId: authData.productId,
            pricePoint: authData.pricePointHandle,
          })
              .then((response) => {
                handleUpgradeOrCancellationMigration(
                    authData,
                    response,
                    activeSubscription,
                    preselectedFromLanding,
                    contestSession,
                    contestRedirectURL
                );
              })
              .catch((error) => {
                setLoading(false)
                // console.log(error.response)
              });
        } else {
          //upgrade or make use of coupon

          //filter cms program data
          setCmsProgramsData(PickProgramPageHelper.getPrograms(pickProgramData, CmsHelper.UPGRADE_PROGRAM))

          ProductsService.getProductReferenceStandalone({...activeSubscription})
              .then((response) => {
                // console.log("program: received products")
                handleUpgradeOrCancellationMigration(
                    authData,
                    response,
                    activeSubscription,
                    preselectedFromLanding,
                    contestSession,
                    contestRedirectURL
                );
              })
              .catch((error) => {
                setLoading(false)
                // console.log(error.response)
              });
        }
      } else {
        //user just registered or isnt initialized yet

        //filter cms program data
        setCmsProgramsData(PickProgramPageHelper.getPrograms(pickProgramData, CmsHelper.REGISTRATION))

        //case user don't have a subscription yet
        ProductsService.getProductsStandalone()
          .then((response) => {
            let products = [];

            if (couponExists) {
              products = [...couponInfo.references];
            } else {
              if(sessionStorage.getItem("promoTargetDisplayName")){
                const targetDisplayNames = JSON.parse(sessionStorage.getItem("promoTargetDisplayName"));
                products = response.data.packages.filter(item1 => targetDisplayNames.some(item2 => item1.displayName === item2))
                sessionStorage.removeItem("promoTargetDisplayName")
              }else{
                products = [...response.data.packages];
              }
            }

            if(contestSession){
              products = products.filter(product => product.displayName !== "Lite")
            }

            setPackages(products);
            if (products.length > 0) {
              // id to preselect if there is no preselected so Free product is not preselected
              let id = products.find((item) => {
                if (item.displayName !== "Lite") {
                  setLoading(false)
                  return true;
                }
                setLoading(false)
                return false;
              })
                ? products.find((item) => item.displayName === "F1")
                  ? products.find((item) => item.displayName === "F1")
                  : products[0]
                : products[0];

              let preSelected = undefined;
              if (preselectedFromLanding !== null) {
                preSelected = products.find(
                  (element) =>
                    element.productId === preselectedFromLanding.productId &&
                    element.pricePoint === preselectedFromLanding.pricePoint
                );
              }

              if (preSelected) {
                setChosenProgram(preSelected);
                setSubscription({
                  productId: preSelected.productId,
                  newProductId: preSelected.productId,
                });
                serPreselected(preSelected);
              } else {
                setChosenProgram(id);
                setSubscription({
                  productId: id.productId,
                  newProductId: id.productId,
                });
              }
            } else {
              setChosenProgram({ productId: -1, pricePoint: "" });
              setSubscription({ productId: -1 });
            }
            setLoading(false)
          })
          .catch((err) => {
            setLoading(false)
            //console.log(err);
          });
      }
    }
  }, [loggedIn]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleUpgradeOrCancellationMigration = (
    userData,
    response,
    activeSubscription,
    preselectedFromLanding,
    contestSession,
    contestSessionRedirectURL
  ) => {
    setHasFreeProduct(response.data.downgrades.length === 0);
    if(contestSession && !(response.data.downgrades.length === 0)){
      sessionStorage.setItem("contestSession", contestSession);
      sessionStorage.setItem("contestRedirectURL", contestSessionRedirectURL);
      navigate("/CongratsPage");
    }
    let upgrades = [];
    if (couponExists) {
      upgrades = [...couponInfo.references];
    } else {
      if(sessionStorage.getItem("promoTargetDisplayName")){
        const targetDisplayNames = JSON.parse(sessionStorage.getItem("promoTargetDisplayName"));
        upgrades = response.data.upgrades.filter(item1 => targetDisplayNames.some(item2 => item1.displayName === item2))
        sessionStorage.removeItem("promoTargetDisplayName")
        if(upgrades.length === 0){
          window.open(promoRedirectURL, "_self")
          return;
        }
      }else{
        upgrades = [...response.data.upgrades];
      }
    }

    if (upgrades.length === 0) {
      onUnableToUpgrade();
      setLoading(false)
      return;
    }

    // coupon flow to just add coupon to current product
    if (
      couponExists &&
      upgrades.length === 1 &&
      upgrades[0].id === activeSubscription.productId &&
      upgrades[0].pricePoint === activeSubscription.pricePoint
    ) {
      onUseCoupon(sessionStorage.getItem("discount-coupon"));
      setLoading(false)
      return;
    }

    // set products to display
    setPackages(upgrades);

    let preSelected = undefined;
    if (preselectedFromLanding !== null) {
      preSelected = upgrades.find(
        (element) =>
          element.productId === preselectedFromLanding.productId &&
          element.pricePoint === preselectedFromLanding.pricePoint
      );
    }

    if (preSelected) {
      setChosenProgram(preSelected);
      setSubscription({
        productId: preSelected.productId,
        newProductId: preSelected.productId,
        subscriptionId: userData.subscriptionId,
      });

      serPreselected(preSelected);
    } else {
      let id = upgrades.find((item) => {
        if (item.displayName !== "Lite") {
          setLoading(false)
          return true;
        }
        setLoading(false)
        return false;
      })
          ? upgrades.find((item) => ["F1", "F1offer20", "F1offer9"].includes(item.displayName))
              ? upgrades.find((item) => ["F1", "F1offer20", "F1offer9"].includes(item.displayName))
              : upgrades[0]
          : upgrades[0];
      setChosenProgram(id);
      setSubscription({
        productId: upgrades[0].productId,
        newProductId: upgrades[0].productId,
        subscriptionId: userData.subscriptionId,
      });
    }
    setUpgrade(true);
    setLoading(false)
  };

  const handleTokenSaveDeskMigration = (
      userData,
      response,
      activeSubscription,
      preselectedFromLanding
  ) => {
    // setHasFreeProduct(response.data.downgrades.length === 0);

    let upgrades = [];
    if (couponExists) {
      upgrades = [...couponInfo.references];
    } else {
      upgrades = [...response.data.packages];
    }

    if (upgrades.length === 0) {
      onUnableToUpgrade();
      setLoading(false)
      return;
    }

    // coupon flow to just add coupon to current product
    if (
        couponExists &&
        upgrades.length === 1 &&
        upgrades[0].productId === activeSubscription.productId &&
        upgrades[0].pricePoint === activeSubscription.pricePoint
    ) {
      onUseCoupon(sessionStorage.getItem("discount-coupon"));
      setLoading(false)
      return;
    }

    // set products to display
    setPackages(upgrades);

    let preSelected = undefined;
    if (preselectedFromLanding !== null) {
      preSelected = upgrades.find(
          (element) =>
              element.productId === preselectedFromLanding.productId &&
              element.pricePoint === preselectedFromLanding.pricePoint
      );
    }

    if (preSelected) {
      setChosenProgram(preSelected);
      setSubscription({
        productId: preSelected.productId,
        newProductId: preSelected.productId,
        subscriptionId: userData.subscriptionId,
      });

      serPreselected(preSelected);
    } else {
      setChosenProgram(upgrades[0]);
      setSubscription({
        productId: upgrades[0].productId,
        newProductId: upgrades[0].productId,
        subscriptionId: userData.subscriptionId,
      });
    }
    setUpgrade(true);
    setLoading(false)
  };

  const onUnableToUpgrade = () => {
    setCanUpgrade(false);
  };

  const onSubscribe = (subscription) => {
    setLoading(true);
    sessionStorage.removeItem("selectedProgramId");
    if(contestState){
      sessionStorage.setItem("contestSession", contestState)
      sessionStorage.setItem("contestRedirectURL", contestRedirectURLState)
    }
    AccountsService.subscribe(subscription)
      .then((response) => {
        const newProduct = response.data.subscription.product
        account.setupAnalyticsProductConvertion({
          pricePoint: newProduct.product_price_point_handle,
          productName: newProduct.name,
          pricePointId: newProduct.product_price_point_id,
        })

        sessionStorage.setItem("kc_resolve_token", response.data.token);
        if(promoRedirectURLState){
          sessionStorage.setItem("congratsRedirectURL", promoRedirectURLState)
        }
        if (UserService.isSubscriptionAction()) {
          navigate("/CongratsPage");
        } else {
          setLoading(false);
          navigate("/CongratsPage");
        }
      })
      .catch((err) => {
        setLoading(false);
        account.setProductId(subscription.productId);
        account.setPricePoint(subscription.pricePoint);
        if ([402, 300].includes(err.response.data.code)) {
          account.setChargifyAccess(true);
          if(!!promoRedirectURLState){
            sessionStorage.setItem("promoRedirectURL", promoRedirectURLState)
          }
          navigate("/ChargifyForm");
        } else if ([401, 422].includes(err.response.status)) {
          UserService.clearActionToken();
          // console.debug("Retrying authorization")
          try{
            UserService.doLogin(window.location.origin + "/PickProgram",account.language);
          }catch (e){
            // console.debug("Error slow connection")
          }
        } else if (err.response.data.code === 403) {
          setCheckLoggedIn(false);
          sessionStorage.setItem(
            "cardValidationToken",
            err.response.data.token
          );
          sessionStorage.setItem(
            "subscriptionRequest",
            JSON.stringify(subscription)
          );
          navigate("/PhoneVerification");
        } else if (err.response.data.code === 404) {
          setCardInUse(true);
        } else {
          setError(true);
        }
      });
  };

  const onUpgrade = (subscription) => {
    sessionStorage.removeItem("selectedProgramId");
    if(contestState){
      sessionStorage.setItem("contestSession", contestState)
      sessionStorage.setItem("contestRedirectURL", contestRedirectURLState)
      sessionStorage.setItem("isContestUpgrade", "true");
    }
    AccountsService.upgradeSubscription(subscription)
      .then((response) => {
        const newProduct = response.data.subscription.product
        account.setupAnalyticsProductConvertion({
          pricePoint: newProduct.product_price_point_handle,
          productName: newProduct.name,
          pricePointId: newProduct.product_price_point_id,
        })

        if (sessionStorage.getItem("discount-coupon")) {
          sessionStorage.removeItem("discount-coupon");
        }
        if(subscription.couponCodes){
          setSuccesfulCouponUse(true);
          setFailedCouponUse(false);
        }else{
          if(promoRedirectURLState){
            sessionStorage.setItem("congratsRedirectURL", promoRedirectURLState)
          }
          setLoading(false)
          navigate("/CongratsPage");
        }
        setLoading(false)
      })
      .catch((err) => {
        account.setProductId(subscription.productId);
        account.setPricePoint(subscription.pricePoint);
        account.setSkipFilters(hasFreeProduct && couponExists);
        if(subscription.token){
          sessionStorage.setItem("save_desk_token", subscription.token)
        }
        if ([402, 300].includes(err.response.data.code)) {
          account.setChargifyAccess(true);
          if(promoRedirectURLState){
            sessionStorage.setItem("promoRedirectURL", promoRedirectURLState)
          }
          setLoading(false)
          navigate("/ChargifyForm");
        } else if (err.response.data.code === 403) {
          setCheckLoggedIn(false);
          sessionStorage.setItem("cardValidationFlow", "upgrade");
          sessionStorage.setItem(
            "cardValidationToken",
            err.response.data.token
          );
          sessionStorage.setItem(
            "subscriptionRequest",
            JSON.stringify(subscription)
          );
          setLoading(false)
          navigate("/PhoneVerification");
        } else if (err.response.data.code === 404) {
          setCardInUse(true);
        }else if (err.response.data.code === 425) {
          setSameProductVerification(true);
        } else {
          setError(true);
        }
        setLoading(false)
      });
  };

  const onNext = (product) => {
    setLoading(true)
    let request = {
      productId: product.productId,
      newProductId: product.productId,
      pricePoint: product.pricePoint,
    };

    if (sessionStorage.getItem("discount-coupon")) {
      request = {
        ...request,
        couponCodes: [`${sessionStorage.getItem("discount-coupon")}`],
      };
    }
    setSubscription(request);
    account.setChosenProgramName(
      packages.find((obj) => obj.productId === product.productId).name
    );
    GoogleAnalytics.onSelectPackage();
    AwsApiService.onPickProgram();

    if (
      sessionStorage.getItem("discount-coupon") &&
      !!authData.subscriptionId
    ) {
      if (authData.productId === product.productId &&
          authData.pricePoint === product.pricePoint) {
        onUseCoupon(sessionStorage.getItem("discount-coupon"));
        return;
      }
    }

    if (!upgrade) {
      onSubscribe(request);
    } else {
      onUpgrade({...request, ...(token !== null ? { token: token } : {})})
    }
  };

  const onUseSameCard = () => {
    upgrade
      ? onUpgrade({ ...subscription, noTrialAccepted: true })
      : onSubscribe({ ...subscription, noTrialAccepted: true });
  };

  const onVerifyCouponRemoval = () => {
    setLoading(true)
    setSameProductVerification(false)
    upgrade
        ? onUpgrade({ ...subscription, verified: true })
        : onSubscribe({ ...subscription, verified: true });
  }

  const onAfterSuccessfulCouponUse = () => {
    if(promoRedirectURL){
      sessionStorage.setItem("congratsRedirectURL", promoRedirectURL)
    }
    sessionStorage.setItem("coupon-success", true);
    navigate("/CongratsPage");
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="programs-wrapper">
          <div className="programs box">
            {(update !== undefined && !update) && !couponExists && (
              <div id="progress">
                <div className="line"></div>
                <div className="circle-wrapper">
                  <span>{t("psProgressTitle1")}</span>
                  <div className="circle" id="step1">
                    <CheckIcon id="check-icon" />
                  </div>
                </div>
                <div className="circle-wrapper">
                  <span>{t("psProgressTitle2")}</span>
                  <div className="circle" id="step2">
                    2
                  </div>
                </div>
                <div className="circle-wrapper">
                  <span>{t("psProgressTitle3")}</span>
                  <div className="circle" id="step3">
                    3
                  </div>
                </div>
              </div>
            )}

            {packages.length > 0 && productBanners && (
              <div id="cancellation-banner">
                <PickProgramBanner 
                    bannerData={productBanners} 
                    displayName={packages[0].displayName}
                    deviceType={renderingType}
                    type={account.migrateFromCancellation 
                            ? CmsHelper.CANCELLATION_MIGRATE 
                            : (update ? CmsHelper.UPGRADE_PROGRAM : CmsHelper.REGISTRATION)} />
              </div>
            )}

            <section id="product-selection">
              <div id="product-selection-header">
                {packages.length > 0 && (
                  <>
                    {couponExists ? (
                      <h1>Απόλαυσε δωρεάν με τον κωδικό προσφοράς</h1>
                    ) : <h1>{contestState ?
                        update ?
                            "Αναβάθμισε το πρόγραμμά σου για να πάρεις μέρος στον διαγωνισμό!"
                            :
                            "Επίλεξε το πρόγραμμά σου για να πάρεις μέρος στον διαγωνισμό!"
                        :
                        cmsProgramsData?.title}</h1>
                    }
                  </>
                )}
                {canUpgrade && packages.length > 0 && !couponExists && (
                    <h3>{cmsProgramsData?.details}</h3>
                )}
              </div>

              <div id="product-list">
                {packages.map((product, index) => {
                  return (
                    <ProductContainer key={index} product={product} index={index} update={update} couponExists={couponExists}
                                      setChosenProgram={setChosenProgram} chosenProgram={chosenProgram}
                                      packagesRef={packagesRef} packagesLength={packages.length}
                                      onNext={onNext}/>
                    )})}
                  </div>
            </section>
            <PopUp
              openPopUp={error}
              setOpenPopUp={setError}
              maxWidth="md"
              showCloseButton={false}
              outlined={false}
              error={true}
              closeWhenClickedOutside={false}
            >
              <GenericError
                setClose={setError}
                mainText={"Υπήρξε κάποιο πρόβλημα στην επιλογή προγράμματος"}
                secondaryText={"Παρακαλώ δοκιμάστε αργότερα"}
              />
            </PopUp>
            <PopUp
                openPopUp={sameProductVerification}
                setOpenPopUp={setSameProductVerification}
                maxWidth="md"
                showCloseButton={false}
                outlined={false}
                error={true}
            >
              <GenericError
                  setClose={setSameProductVerification}
                  success={true}
                  mainText={"Με την προσθήκη νέου κωδικού προσφοράς, τυχόν υπόλοιπο δωρεάν χρήσης της υπηρεσίας εξαντλείται αυτόματα."}
                  secondaryText={"Σε περίπτωση που αποδέχεσαι την άμεση ενεργοποίηση της νέας προσφοράς πάτησε Συνέχεια, διαφορετικά πάτησε Επιστροφή και μπορείς να την ενεργοποιήσεις όταν λήξει η ήδη υπάρχουσα."}
                  showIcon={false}
                  hideButtons={true}
              />
              <div id="card-in-use-actions">
                <Button
                    variant="text"
                    color="primary"
                    className="grey"
                    onClick={() => {
                      sessionStorage.removeItem("discount-coupon")
                      navigate("/Account")
                    }}
                >
                  Επιστροφή
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onVerifyCouponRemoval}
                >
                  Συνέχεια
                </Button>
              </div>
            </PopUp>
            <PopUp
              openPopUp={genericError}
              setOpenPopUp={setGenericError}
              maxWidth="md"
              showCloseButton={false}
              outlined={false}
              error={true}
            >
              <GenericError
                setClose={setGenericError}
                mainText={"Υπήρξε κάποιο πρόβλημα"}
                secondaryText={"Παρακαλώ δοκιμάστε αργότερα"}
              />
            </PopUp>
            <PopUp
              openPopUp={!canUpgrade}
              setOpenPopUp={(can)=>{setCanUpgrade(!can)}}
              maxWidth="md"
              showCloseButton={false}
              outlined={false}
              error={true}
              closeWhenClickedOutside={false}>
              <GenericError
                  setClose={setCanUpgrade}
                  buttonText={"Ξεκίνα να απολαμβάνεις"}
                  executeFunction={function () {
                    window.open(`${process.env.REACT_APP_CONGRATS_BUTTON_LINK}`, "_self");
                  }}
                  showIcon={false}>
                <span className={"pick-program-fallback-modal"}>Έχεις ήδη πρόσβαση σε όλες τις υπηρεσίες και το περιεχόμενο του ANT1+</span>
              </GenericError>
            </PopUp>
            <PopUp
              openPopUp={failedCouponUse}
              setOpenPopUp={setFailedCouponUse}
              maxWidth="md"
              showCloseButton={false}
              outlined={false}
              error={true}
            >
              <GenericError
                setClose={setFailedCouponUse}
                success={true}
                mainText={"Αποτυχία εξαργύρωσης!"}
                secondaryText={"Ο κωδικός δεν είναι έγκυρος"}
                buttonText={"Συνέχεια"}
                executeFunction={() => navigate("/Account")}
                showIcon={false}
              />
            </PopUp>
            <PopUp
              openPopUp={successfulCouponUse}
              setOpenPopUp={setSuccesfulCouponUse}
              maxWidth="md"
              showCloseButton={false}
              outlined={false}
              error={false}
              closeWhenClickedOutside={false}
            >
              <GenericError
                setClose={setSuccesfulCouponUse}
                success={true}
                mainText={"Επιτυχής εξαργύρωση κωδικού!"}
                secondaryText={"Απόλαυσε δωρεάν όλο το περιεχόμενο του ΑΝΤ1+."}
                buttonText={"Συνέχεια"}
                showIcon={false}
                executeFunction={() => onAfterSuccessfulCouponUse()}
              />
            </PopUp>
            <PopUp
              openPopUp={cardInUse}
              setOpenPopUp={setCardInUse}
              maxWidth="md"
              showCloseButton={false}
              outlined={false}
              error={true}
            >
              <GenericError
                setClose={setCardInUse}
                success={true}
                mainText={t("cardHashError")}
                secondaryText={t("cardHashErrorDescription")}
                showIcon={false}
                hideButtons={true}
              />
              <div id="card-in-use-actions">
                <Button
                  variant="text"
                  color="primary"
                  className="grey"
                  onClick={() => {
                    setCardInUse(false);
                  }}
                >
                  Αλλαγή κάρτας
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onUseSameCard}
                >
                  Συμφωνώ & επιθυμώ να συνεχίσω
                </Button>
              </div>
            </PopUp>
            <PopUp
                openPopUp={SaveDesk2error}
                setOpenPopUp={setSaveDesk2error}
                maxWidth="md"
                showCloseButton={false}
                outlined={false}
                error={true}
                closeWhenClickedOutside={false}
            >
              <GenericError
                  setClose={setSaveDesk2error}
                  mainText={
                    "Δεν βρέθηκε προσφορά για εσένα."
                  }
                  executeFunction={function () {
                    navigate("/");
                  }}
                  showIcon={false}
              />
            </PopUp>
          </div >
        </div >
      )
      }
    </>
  );
}

export default Program;

function PickProgramBanner({ bannerData, deviceType, type, displayName }) {
  const banner = ProgramBannersHelper.getBanner(bannerData, type, deviceType, displayName)
                  ?? ProgramBannersHelper.getGenericBanner(bannerData, type, deviceType);

  return (banner?.banner && 
    <img
        src={CmsHelper.getImagePath(banner.banner)}
        alt="product banner"
        id={deviceType.toLowerCase()}
        style={{width: '100%'}}
      /> );
}