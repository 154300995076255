import React from "react";

const couponFieldNames = {
  "actualPrice": "actualPriceWithCoupon",
  "priceNoDiscount": "priceNoDiscountWithCoupon",
  "priceDescription": "priceDescriptionWithCoupon",
  "priceDescriptionNoDiscount": "priceDescrNoDiscountWithCoupon",
  "priceDetails": "priceDetailsWithCoupon"
};

const convertPriceToText = (price) => {
  return price.toString().replace(".",",");
}

function ProductPriceInfo({ displayName, coupon, cmsInfo }) {
  return cmsInfo && (
    <>
      <div>
        {cmsInfo.priceDescriptionNoDiscount &&
            <span className="cross light">
              <FieldValue coupon={coupon} cmsInfo={cmsInfo} fieldName="priceDescriptionNoDiscount"/>
            </span>}
        {cmsInfo.priceDescription &&
            <>
              <span>
                <FieldValue coupon={coupon} cmsInfo={cmsInfo} fieldName="priceDescription"/>
              </span>
            </>}
        {cmsInfo.priceDetails &&
          <>
            <span className="light">
              <FieldValue coupon={coupon} cmsInfo={cmsInfo} fieldName="priceDetails" />
            </span> <br />
          </>}
      </div>

      {cmsInfo.actualPrice && (
        <div>
          {cmsInfo.priceNoDiscount && (<span className="cross light"><FieldValue coupon={coupon} cmsInfo={cmsInfo} fieldName="priceNoDiscount" priceVal />€</span>)}
          <span className="bold"><FieldValue coupon={coupon} cmsInfo={cmsInfo} fieldName="actualPrice" priceVal />€</span>/ {cmsInfo.paymentDurationDescription}
        </div>)}
    </>
  );
}


function FieldValue({coupon, cmsInfo, fieldName, priceVal = false}) {
  const couponFieldname = couponFieldNames[fieldName];

  const retVal = coupon
                  ? (cmsInfo[couponFieldname] ?? cmsInfo[fieldName])
                  : cmsInfo[fieldName];

  return priceVal 
          ? convertPriceToText(retVal) 
          : retVal;
}

  // if (displayName === "Lite") {
  //   return <FreePrices coupon={coupon} />;
  // } else if (["Full", "saveDesk"].includes(displayName)) {
  //   return <FullPrices coupon={coupon} />;
  // } else if (displayName === "F1") {
  //   return <F1Prices coupon={coupon} />;
  // } else if (displayName === "Village") {
  //   return <VillagePrices coupon={coupon} />;
  // } else if (displayName === "F1shadow") {
  //   return <F1ShadowPrices coupon={coupon} />;
  // } else if (displayName === "F1offer9") {
  //   return <F1Offer9 coupon={coupon} />;
  // } else if (displayName === "F1offer20") {
  //   return <F1Offer20 coupon={coupon} />;
  // } else if (displayName === "ValuePackDeiMonthly") {
  //   return <ValuePackDeiMonthly coupon={coupon} />;
  // } else if (displayName === "ValuePackSaveDesk") {
  //   return <ValuePackSaveDeskPrices coupon={coupon} />;
  // } else if (displayName === "vprescuepoint") {
  //   return <VPRescuePointPrices coupon={coupon} />;
  // } else if (displayName === "ValuePackSaveDeskOct23") {
  //   return <ValuePackSaveDeskOct23Prices coupon={coupon} />;
  // } else if (displayName === "SixMonthersOffer19") {
  //   return <SixMonthersOffer19Prices coupon={coupon} />;
  // } else {
  //   return null;
  // }
// }

export default ProductPriceInfo;

// function VillagePrices({ coupon }) {
//   const villageDefault = (
//     <>
//       <div>προσφορά γνωριμίας</div>
//       <div>
//         <span className="cross light">14,99€</span>
//         <span className="bold">9,99€</span>/ μήνα
//       </div>
//     </>
//   );

//   const couponDefault = (
//     <>
//       <div>προσφορά γνωριμίας</div>
//       <div>
//         <span className="cross light">14,99€</span>
//         <span className="bold">9,99€</span>/ μήνα
//       </div>
//     </>
//   );

//   if (coupon) {
//     return couponDefault;
//   } else {
//     return villageDefault;
//   }
// }

// function F1Prices({ coupon }) {
//   const f1Default = (
//     <>
//       <div>
//         <span>49,99€ εφάπαξ</span>
//         <span className="light">για 9 μήνες</span> <br />
//       </div>
//       <div>
//         <span className="bold">5,55€</span>/ μήνα
//       </div>
//     </>
//   );

//   const couponDefault = (
//     <>
//       <div>
//         <span>49,99€ εφάπαξ</span>
//         <span className="light">για 9 μήνες</span> <br />
//       </div>
//       <div>
//         <span className="bold">5,55€</span>/ μήνα
//       </div>
//     </>
//   );

//   if (coupon) {
//     return couponDefault;
//   } else {
//     return f1Default;
//   }
// }

// function F1ShadowPrices({ coupon }) {
//   const f1Default = (
//     <>
//       <div>
//         <span className="cross light">39,99€</span> <span>9€ εφάπαξ</span>
//         <span className="light">για 9 μήνες</span> <br />
//       </div>
//       <div>
//         <span className="bold">1€</span>/ μήνα
//       </div>
//     </>
//   );

//   const couponDefault = (
//     <>
//       <div>
//         <span className="cross light">39,99€</span> <span>9€ εφάπαξ</span>
//         <span className="light">για 9 μήνες</span> <br />
//       </div>
//       <div>
//         <span className="bold">1€</span>/ μήνα
//       </div>
//     </>
//   );

//   if (coupon) {
//     return couponDefault;
//   } else {
//     return f1Default;
//   }
// }

// function F1Offer9({ coupon }) {
//   const f1Default = (
//     <>
//       <div>
//         <span className="cross light">39,99€</span> <span>9€ εφάπαξ</span>
//         <span className="light">για 9 μήνες</span> <br />
//       </div>
//       <div>
//         <span className="bold">1€</span>/ μήνα
//       </div>
//     </>
//   );

//   const couponDefault = (
//     <>
//       <div>
//         <span className="cross light">39,99€</span> <span>9€ εφάπαξ</span>
//         <span className="light">για 9 μήνες</span> <br />
//       </div>
//       <div>
//         <span className="bold">1€</span>/ μήνα
//       </div>
//     </>
//   );

//   if (coupon) {
//     return couponDefault;
//   } else {
//     return f1Default;
//   }
// }

// function F1Offer20({ coupon }) {
//   const f1Default = (
//     <>
//       <div>
//         <span className="cross light">49,99€</span> <span>29,99€ εφάπαξ</span>
//         <span className="light">για 9 μήνες</span> <br />
//       </div>
//       <div>
//         <span className="bold">3,33€</span>/ μήνα
//       </div>
//     </>
//   );

//   const couponDefault = (
//     <>
//       <div>
//         <span className="cross light">49,99€</span> <span>29,99€ εφάπαξ</span>
//         <span className="light">για 9 μήνες</span> <br />
//       </div>
//       <div>
//         <span className="bold">3,33€</span>/ μήνα
//       </div>
//     </>
//   );

//   if (coupon) {
//     return couponDefault;
//   } else {
//     return f1Default;
//   }
// }

// function ValuePackDeiMonthly({ coupon }) {
//   const f1Default = (
//     <>
//       <div>
//         <span className="light cross">49,99€</span>
//         <span>δωρεάν για 9 μήνες</span> <br />
//         <span className="light">μετά</span>
//         <span className="bold">7,99€</span>/ μήνα*
//       </div>
//     </>
//   );

//   const couponDefault = (
//     <>
//       <div>
//         <span className="light cross">49,99€</span>
//         <span>δωρεάν για 9 μήνες</span> <br />
//         <span className="light">μετά</span>
//         <span className="bold">7,99€</span>/ μήνα*
//       </div>
//     </>
//   );

//   if (coupon) {
//     return couponDefault;
//   } else {
//     return f1Default;
//   }
// }

// function FullPrices({ coupon }) {
//   const fullDefault = (
//     <>
//       <div>
//         <span className="light"></span> <br />
//         <span className="bold">7,99€</span>/ μήνα
//       </div>
//     </>
//   );

//   const couponDefault = (
//     <>
//       <div>
//         <span className="light">δωρεάν για 30 ημέρες</span> <br />
//         <span className="light">μετά</span>
//         <span className="bold">7,99€</span>/ μήνα
//       </div>
//     </>
//   );

//   if (coupon) {
//     return couponDefault;
//   } else {
//     return fullDefault;
//   }
// }

// function FreePrices({ coupon }) {
//   const freeDefault = <></>;

//   const couponDefault = <></>;

//   if (coupon) {
//     return couponDefault;
//   } else {
//     return freeDefault;
//   }
// }

// function ValuePackSaveDeskPrices({ coupon }) {
//   const saveDeskDefault = (
//     <>
//       <div>
//         <span className="cross light">39,99€</span>
//         <span>19,99€ εφάπαξ</span>
//         <span className="light">για 9 μήνες</span> <br />
//         <span className="bold">2,22€</span>/ μήνα
//       </div>
//     </>
//   );

//   const couponDefault = (
//     <>
//       <div>
//         <span className="cross light">39,99€</span>
//         <span>19,99€ εφάπαξ</span>
//         <span className="light">για 9 μήνες</span> <br />
//         <span className="bold">2,22€</span>/ μήνα
//       </div>
//     </>
//   );

//   if (coupon) {
//     return couponDefault;
//   } else {
//     return saveDeskDefault;
//   }
// }

// function ValuePackSaveDeskOct23Prices({ coupon }) {
//   const saveDeskDefault = (
//     <>
//       <div>
//         <span className="cross light">49,99€</span>
//         <span>39,99€ εφάπαξ</span>
//         <span className="light">για 9 μήνες</span> <br />
//         <span className="bold">4,44€</span>/ μήνα
//       </div>
//     </>
//   );

//   const couponDefault = (
//     <>
//       <div>
//         <span className="cross light">49,99€</span>
//         <span>39,99€ εφάπαξ</span>
//         <span className="light">για 9 μήνες</span> <br />
//         <span className="bold">4,44€</span>/ μήνα
//       </div>
//     </>
//   );

//   if (coupon) {
//     return couponDefault;
//   } else {
//     return saveDeskDefault;
//   }
// }

// function VPRescuePointPrices({ coupon }) {
//   const vprescuepointDefault = (
//     <>
//       <div>
//         <span className="light cross">39,99€</span>
//         <span>19,99€ εφάπαξ</span>
//         <span className="light">για 9 μήνες</span> <br />
//       </div>
//       <div>
//         <span className="bold">2,22€</span>/ μήνα
//       </div>
//     </>
//   );

//   const couponDefault = (
//     <>
//       <div>
//         <span className="light cross">39,99€</span>
//         <span>19,99€ εφάπαξ</span>
//         <span className="light">για 9 μήνες</span> <br />
//       </div>
//       <div>
//         <span className="bold">2,22€</span>/ μήνα
//       </div>
//     </>
//   );

//   if (coupon) {
//     return couponDefault;
//   } else {
//     return vprescuepointDefault;
//   }
// }

// function SixMonthersOffer19Prices({ coupon }) {
//   const sixMonthersOffer19Default = (
//     <>
//       <div>
//         <span className="light cross">49,99€</span>
//         <span>19,99€ εφάπαξ</span>
//         <span className="light">για 9 μήνες</span> <br />
//       </div>
//       <div>
//         <span className="bold">2,22€</span>/ μήνα
//       </div>
//     </>
//   );

//   const couponDefault = (
//     <>
//       <div>
//         <span className="light cross">49,99€</span>
//         <span>19,99€ εφάπαξ</span>
//         <span className="light">για 9 μήνες</span> <br />
//       </div>
//       <div>
//         <span className="bold">2,22€</span>/ μήνα
//       </div>
//     </>
//   );

//   if (coupon) {
//     return couponDefault;
//   } else {
//     return sixMonthersOffer19Default;
//   }
// }
